import { OutlineButton, PrimaryButton } from 'Components/Buttons';
import { getCurrencySymbol } from 'Components/Subscription/SubscriptionManager/helpers';
import { addMonths, format } from 'date-fns';
import { Box, Text } from 'grommet';
import { FormAdd, FormSubtract } from 'grommet-icons';
import { useEffect, useState } from 'preact/hooks';
import { useDispatch } from 'react-redux';
import { trackMP } from 'Shared/mp';
import useFetch, { CachePolicies } from 'use-http';

import { useSelector } from '@/redux/helper';
import { setSubscriptionData } from '@/redux/slices/user/actions';
import { buildURL } from '@/shared/fetch';

import style from '../CancelReasonsModal/style.scss';

const offerTexts = {
  percentageOff: {
    title: "Here's %X%% off for the next %Y% %Z%",
    description:
      'We are a small team hoping to connect the world through animals and are trying our hardest to build the best product possible.\n\nYour memberships helps to keep us going and receive funding support from our partners to continue building for a better product experience.\n\nWould you care to be patient and bear with us if we gave you %X%% off for the next %Y% %Z%?',
    mpEventName: 'percentage-discount',
  },
  pauseSubscription: {
    title: 'Pause your membership',
    description:
      "We understand that you don't use zoolife right now right now, but we'd hate to lose you as a member.\n\nWould you consider pausing for a couple of months and retain your membership status during your pause?",
    mpEventName: 'subscription-pause',
  },
  oneMonthOnUs: {
    title: 'Get one month for free on us',
    description:
      'Thank you for notifying us of your technical issues. We are a small team and support is on its way. We will try our hardest to resolve this issue for you. While you bear with us, we would like to offer you 1 month of access for free.',
    mpEventName: 'invoice-item-credit',
  },
};

const offerDetails = {
  month: { percentOff: 50, durationInMonths: 3 },
  year: { percentOff: 25, durationInYear: 1 },
};

const QuantitySelector = ({ value, onChange, min, max }) => {
  const handleDecrease = () => {
    if (value > min) onChange(value - 1);
  };

  const handleIncrease = () => {
    if (value < max) onChange(value + 1);
  };

  return (
    <div className={style.quantitySelector}>
      <button onClick={handleDecrease} disabled={value <= min} type="button">
        <FormSubtract size="small" />
      </button>
      <span className={style.quantityValue}>
        {value} <span className={style.quantityUnit}>month{value !== 1 ? 's' : ''}</span>
      </span>
      <button onClick={handleIncrease} disabled={value >= max} type="button">
        <FormAdd size="small" />
      </button>
    </div>
  );
};

const CancelationFlowOffer = ({ offerType, onNoThanks, onAfterClaimOfferClose }) => {
  const [offerClaimed, setOfferClaimed] = useState(false);
  const [pauseDuration, setPauseDuration] = useState(1);
  const [apiResponse, setApiResponse] = useState(null);
  const [apiError, setApiError] = useState(null);
  const { title, description } = offerTexts[offerType];
  const currentProductNameOfUser = useSelector((state) => state.user.subscription.productName);
  const dispatch = useDispatch();

  const storeClaimedOffer = (offerName) => {
    try {
      const usedOffers = JSON.parse(localStorage.getItem('cancelationFlowUsedOffers') || '[]');
      usedOffers.push({ name: offerName, date: new Date().toISOString() });
      localStorage.setItem('cancelationFlowUsedOffers', JSON.stringify(usedOffers));
    } catch (error) {
      console.error('Error writing to localStorage:', error);
    }
  };

  const noThanksHandler = () => {
    trackMP(`${offerTexts[offerType].mpEventName}-declined-by-user`);
    onNoThanks();
  };

  useEffect(() => {
    trackMP(`${offerTexts[offerType].mpEventName}-presented-to-user`);
  }, [offerType]);

  useEffect(() => {
    if (apiError) {
      trackMP(`${offerTexts[offerType].mpEventName}-error`, { error: apiError });
    }
  }, [apiError, offerType]);

  const { post, response, loading } = useFetch(buildURL('/'), {
    cachePolicy: CachePolicies.NO_CACHE,
  });

  const handleClaimOffer = async () => {
    if (offerType === 'percentageOff') {
      const result = await post('checkout/applyDiscount');
      if (response.ok) {
        setApiResponse(result);
        setOfferClaimed(true);
        storeClaimedOffer(offerType);
      } else {
        setApiError(result.error || 'An error occurred while claiming the offer.');
      }
    } else if (offerType === 'pauseSubscription') {
      const result = await post('users/subscription/unsubscribe', { pauseDuration });
      if (response.ok) {
        setApiResponse(result);
        setOfferClaimed(true);
        dispatch(setSubscriptionData(result.subscriptionStatus));
      } else {
        setApiError(result.error || 'An error occurred while pausing the subscription.');
      }
    } else if (offerType === 'oneMonthOnUs') {
      const result = await post('checkout/invoiceItemCredit', { creditName: 'oneMonthOnUs' });
      if (response.ok) {
        setApiResponse(result);
        setOfferClaimed(true);
        dispatch(setSubscriptionData(result.user.subscriptionStatus));
        storeClaimedOffer(offerType);
      } else {
        setApiError(result.error || 'An error occurred while claiming the free month offer.');
      }
    }
  };

  const replacePlaceholders = (text) => {
    const isMonthly = currentProductNameOfUser.toLowerCase().startsWith('monthly');
    const offerDetail = isMonthly ? offerDetails.month : offerDetails.year;
    const { percentOff } = offerDetail;
    const duration = isMonthly ? offerDetail.durationInMonths : offerDetail.durationInYear;
    const unit = isMonthly ? 'months' : 'year';

    return text.replace(/%X%/g, percentOff).replace(/%Y%/g, duration).replace(/%Z%/g, unit);
  };

  const renderDescription = (text) => {
    const replacedText = replacePlaceholders(text);
    return replacedText.split('\n').map((line, index) => (
      <span key={line}>
        {line}
        {index < replacedText.split('\n').length - 1 && <br />}
      </span>
    ));
  };

  const renderPauseDurationInput = () => {
    if (offerType !== 'pauseSubscription') return null;

    return (
      <Box
        direction="row"
        align="center"
        justify="between"
        margin={{ vertical: 'medium' }}
        className={style.pauseDurationContainer}
      >
        <Text className={style.pauseDurationText}>Select pause duration:</Text>
        <QuantitySelector
          value={pauseDuration}
          onChange={setPauseDuration}
          min={apiResponse ? pauseDuration : 1}
          max={apiResponse ? pauseDuration : 6}
        />
      </Box>
    );
  };

  const renderSuccessMessage = () => {
    if (!apiResponse) return null;

    if (offerType === 'percentageOff') {
      const { discountDetails, upcomingInvoice } = apiResponse;
      const { date, amount, currency } = upcomingInvoice;
      const formattedDate = format(new Date(date), 'dd MMM yyyy');
      const currencySymbol = getCurrencySymbol(currency);
      const isYearly = discountDetails.durationInMonths === 12;

      return (
        <p className={style.successMessage}>
          ✔{' '}
          {`Offer successfully claimed! You've received a ${discountDetails.percentOff}% discount
          for ${isYearly ? '1 year' : `${discountDetails.durationInMonths} months`}.
          You will be charged only ${currencySymbol}${amount} for the 
          ${isYearly ? 'next year' : `next ${discountDetails.durationInMonths} months`}, 
          starting your next billing cycle on ${formattedDate}.`}
        </p>
      );
    }

    if (offerType === 'pauseSubscription') {
      const { subscriptionStatus } = apiResponse;
      const validUntil = new Date(subscriptionStatus.validUntil);
      const resumeDate = addMonths(validUntil, pauseDuration);
      return (
        <p className={style.successMessage}>
          ✔{' '}
          {`Your subscription has been paused successfully
        . Your current subscription will be valid until ${format(validUntil, 'dd MMM yyyy')}. After that
        , it will be paused for ${pauseDuration} month${pauseDuration > 1 ? 's' : ''}
        . Your subscription will resume on ${format(resumeDate, 'dd MMM yyyy')}.`}
        </p>
      );
    }

    if (offerType === 'oneMonthOnUs') {
      const { user } = apiResponse;
      const validUntil = new Date(user.subscriptionStatus.validUntil);
      const formattedDate = format(validUntil, 'dd MMM yyyy');
      const currencySymbol = getCurrencySymbol(user.subscriptionStatus.stripeCurrency);

      return (
        <p className={style.successMessage}>
          ✔{' '}
          {`Great news! You've received one month free. You will be charged ${currencySymbol}0 on your next billing cycle, ${formattedDate}.`}
        </p>
      );
    }

    return null;
  };

  return (
    <div className={style.offerContainer}>
      <h4 className={style.offerTitle}>{replacePlaceholders(title)}</h4>
      <p className={style.offerDescription}>{renderDescription(description)}</p>
      {renderPauseDurationInput()}
      {offerClaimed ? (
        <>
          {renderSuccessMessage()}
          <PrimaryButton label="Close" onClick={onAfterClaimOfferClose} className={style.closeButton} />
        </>
      ) : (
        <div className={style.offerButtonsContainer}>
          <OutlineButton
            label={apiError ? 'Close' : 'No thanks'}
            onClick={noThanksHandler}
            className={style.noThanksButton}
          />
          <PrimaryButton
            label="Claim offer"
            onClick={handleClaimOffer}
            className={style.claimOfferButton}
            disabled={offerClaimed || loading}
            loading={loading}
          />
        </div>
      )}
      {apiError && <p className={style.errorMessage}>{apiError}</p>}
    </div>
  );
};

export default CancelationFlowOffer;
