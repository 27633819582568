import { useSelector } from 'react-redux';

import { logLandingButton } from '@/shared/ga';

import style from './style.scss';
import zoolifeLogo from './zoolife.svg';

const ZoolifeLogo = () => {
  const isLoggedIn = useSelector((state) => state.user.logged);

  return (
    <div>
      <a href={!isLoggedIn ? '/' : '/home'} onClick={() => logLandingButton('Header-Logo')}>
        <img width={97} className={style.img} src={zoolifeLogo} alt="zoolife" />
      </a>
    </div>
  );
};

export default ZoolifeLogo;
