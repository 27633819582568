import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { produce } from 'immer';

import { getAnnouncementsQueryOptions, viewAnnouncementMutationFn } from '@/queries/announcements';
import { AnnouncementWithViewed } from '@/queries/announcements/types';
import { useSelector } from '@/redux/helper';

export const useUnreadAnnouncementsCount = () => {
  const sessionChecked = useSelector((state) => state.user.sessionChecked);
  return useQuery({
    ...getAnnouncementsQueryOptions(),
    select: ({ list }) => list.reduce((acc, item) => acc + (item.isViewed ? 0 : 1), 0),
    enabled: sessionChecked,
  });
};

export const useViewAnnouncementMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: viewAnnouncementMutationFn,
    onSuccess: (_, itemId) => {
      queryClient.setQueryData(
        getAnnouncementsQueryOptions().queryKey,
        produce((cachedResponse: { list: AnnouncementWithViewed[] } | undefined) => {
          const cachedItem = cachedResponse?.list.find(({ _id }) => _id === itemId);
          if (cachedItem) {
            cachedItem.isViewed = true;
          }
          return cachedResponse;
        }),
      );
    },
  });
};
