import { faCalendarDay } from '@fortawesome/pro-solid-svg-icons';
import NavItem from 'Components/NavBar/NavItem';

/**
 * Generates a function comment for the given function body.
 *
 * @param {Object} props - The props object containing the function's parameters.
 * @param {boolean} [props.avoidInnerPadding] - Removes the padding on the inner element.
 * @param {function} [props.onClick] - The function to be called when the item is clicked.
 * @param {string} [props.target] - The target for the talk navigation item.
 * @return {import('preact/compat').ReactElement} The JSX element representing the TalkNavItem component.
 */
const TalkNavItem = ({ onClick, target, avoidInnerPadding }) => {
  return (
    <NavItem
      avoidInnerPadding={avoidInnerPadding}
      text="Events"
      url="/schedule"
      icon={faCalendarDay}
      onClick={onClick}
      target={target}
    />
  );
};

export default TalkNavItem;
