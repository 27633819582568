/* eslint-disable react/jsx-props-no-spreading */
import { Layer } from 'grommet';
import { ReactNode } from 'preact/compat';

import withDefaults from '../withDefaults';
import backgroundImage from './assets/background.svg';

interface MembersOnlyWarningModalProps {
  profilePicture: string;
  children: ReactNode;
  onClickOutside?: () => void;
}

const MembersOnlyWarningModal = ({ profilePicture, children, onClickOutside }: MembersOnlyWarningModalProps) => {
  return (
    <Layer
      className="w-[calc(100%-3rem)] !max-w-[406px] !overflow-auto !rounded-2xl"
      onEsc={onClickOutside}
      onClickOutside={onClickOutside}
    >
      <div className="flex flex-col justify-center rounded-2xl">
        <div className="p-6">
          <div
            style={{ backgroundImage: `url(${backgroundImage})` }}
            className="flex justify-center bg-contain bg-center bg-no-repeat"
          >
            <img
              alt="join zoolife"
              src={profilePicture}
              className="preflight preflight-img w-2/3 max-w-[268px] rounded-full"
            />
          </div>
        </div>
        <div className="flex flex-col gap-6 px-6">{children}</div>
      </div>
    </Layer>
  );
};

MembersOnlyWarningModal.Title = withDefaults('h3', {
  className: 'preflight preflight-h3 mb-2 text-center text-2xl font-bold text-black',
});

MembersOnlyWarningModal.Content = withDefaults('p', {
  className: 'preflight preflight-p text-center text-base font-light text-grey-6',
});

MembersOnlyWarningModal.ButtonsContainer = withDefaults('div', {
  className: 'flex flex-col gap-2 pb-3',
});

export default MembersOnlyWarningModal;
