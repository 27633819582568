import { JSX } from 'preact';
import { useEffect } from 'preact/hooks';

import { useCSSViewportHeightFix, useWindowSize } from '@/hooks';

import { IsMobileLayoutContext } from './contexts';
import MobileLayout from './MobileLayout';

const MOBILE_LAYOUT_WIDTH = 768; // tablet portrait width

interface LayoutDetectorProps {
  desktop: JSX.Element;
  mobile: JSX.Element;
  title: string;
  breakPoint?: number;
}

// eslint-disable-next-line import/prefer-default-export
export const LayoutDetector = ({
  desktop,
  mobile,
  title,
  breakPoint = MOBILE_LAYOUT_WIDTH,
}: LayoutDetectorProps): JSX.Element => {
  useCSSViewportHeightFix();
  const [windowWidth] = useWindowSize();
  const isMobileLayout = windowWidth <= breakPoint;

  useEffect(() => {
    document.body.classList.add(isMobileLayout ? 'group/mobile-layout' : 'group/desktop-layout');
    return () => {
      document.body.classList.remove('group/mobile-layout', 'group/desktop-layout');
    };
  }, [isMobileLayout]);

  return (
    <IsMobileLayoutContext.Provider value={isMobileLayout}>
      {isMobileLayout ? <MobileLayout title={title}>{mobile}</MobileLayout> : desktop}
    </IsMobileLayoutContext.Provider>
  );
};
