/* eslint-disable react/jsx-props-no-spreading */
import { ComponentProps, ComponentType, JSX } from 'preact/compat';

import { cn } from '@/lib/utils';

const withDefaults = <C extends ComponentType<any> | keyof JSX.IntrinsicElements>(
  Component: C,
  defaults: ComponentProps<C>,
) => {
  const Wrapped = (props: ComponentProps<C>) => {
    if (defaults.className) {
      const { className: classNameFromDefaults, ...restDefaults } = defaults;
      const { className: classNameFromProps, ...rest } = props;
      const ComponentWithClassName = Component as ComponentType<{ className: string }>;
      return (
        <ComponentWithClassName className={cn(classNameFromDefaults, classNameFromProps)} {...restDefaults} {...rest} />
      );
    }
    return <Component {...defaults} {...props} />;
  };

  return Wrapped;
};

export default withDefaults;
