import clsx from 'clsx';
import { Text } from 'grommet';

import style from './style.scss';

const Tag = ({
  label,
  varient, // online, light, offline
  className,
}) => (
  <div className={clsx(style.statusTag, className, style[varient])}>
    <Text>{label}</Text>
  </div>
);

export default Tag;
