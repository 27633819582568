import { route } from 'preact-router';

import { selectIsNonFreeHabitat } from '@/redux/selectors/habitats';
import store from '@/redux/store';

import { setMembersOnlyWarningModalState } from '../MembersOnlyWarningModal/redux/actions';
import { MembersOnlyWarningModalState } from '../MembersOnlyWarningModal/redux/types';

export const buildHabitatURL = (zooSlug: string, habitatSlug: string, query?: string) =>
  `/h/${zooSlug}/${habitatSlug}${query ? `?${query}` : ''}`;

export const routeToHabitat = (
  {
    id,
    slug,
    zooSlug,
    query,
  }: {
    id: string;
    slug: string;
    zooSlug: string;
    query?: string;
  },
  type?: MembersOnlyWarningModalState['type'],
) => {
  const state = store.getState();
  const isNonFreeHabitat = selectIsNonFreeHabitat(id)(state);
  if (isNonFreeHabitat) {
    store.dispatch(setMembersOnlyWarningModalState({ habitat: { id, slug, zooSlug }, type }));
  } else {
    route(buildHabitatURL(zooSlug, slug, query));
  }
};
