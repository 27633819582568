import { FunctionalComponent } from 'preact';
import { ReactNode } from 'preact/compat';

import Header from './MobileHeader';
import Tabs from './MobileTabs';
import style from './style.scss';

interface MobileLayoutProps {
  title: string;
  children: ReactNode;
}

const MobileLayout: FunctionalComponent<MobileLayoutProps> = ({ children, title }) => (
  <div className={style.mobileLayout}>
    <Header title={title} />
    <div className={style.mobileLayoutContent}>{children}</div>
    <footer className="relative z-10">
      <Tabs />
    </footer>
  </div>
);

export default MobileLayout;
