import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { Button, ButtonExtendedProps, Text } from 'grommet';
import { HTMLAttributes, ReactNode } from 'preact/compat';

import style from './style.scss';

export interface ZLButtonProps extends Omit<ButtonExtendedProps, 'loading'> {
  loading?: boolean;
}

const getLabel = (loading: boolean | string | undefined, label: ReactNode): string | ReactNode => {
  if (loading && typeof loading === 'boolean') {
    return <FontAwesomeIcon icon={faSpinner} spin style={{ verticalAlign: 'middle', fontSize: '16px' }} />;
  }
  if (loading && typeof loading === 'string') {
    return loading;
  }

  return label;
};

export const PrimaryButton = ({ loading, label, size = 'large', className, ...args }: ZLButtonProps) => (
  <Button
    primary
    size={size}
    className={clsx(className, style[size], { [style.loading]: loading })}
    label={getLabel(loading, label)}
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...args}
  />
);

export const OutlineButton = ({ loading, label, size = 'large', className, ...args }: ZLButtonProps) => (
  <Button
    secondary
    size={size}
    className={clsx(className, style[size], style.outline, { [style.loading]: loading })}
    label={getLabel(loading, label)}
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...args}
  />
);
export const NewOutlineButton = ({ loading, label, size = 'large', className, ...args }: ZLButtonProps) => (
  <Button
    secondary
    size={size}
    className={clsx(className, style[size], style.newOutlineButton, { [style.loading]: loading })}
    label={getLabel(loading, label)}
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...args}
  />
);

export const OutlineButtonDanger = ({ loading, label, size = 'large', className, ...args }: ZLButtonProps) => (
  <Button
    secondary
    size={size}
    className={clsx(style.danger, className, style[size], style.outline, { [style.loading]: loading })}
    label={<Text color="var(--red)">{getLabel(loading, label as string) as string}</Text>}
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...args}
  />
);

export const PrimaryYellow = ({ loading, label, size = 'large', type, className, ...args }: ZLButtonProps) => (
  <Button
    type={type}
    size={size}
    className={clsx(style.yellow, className, style[size], { [style.loading]: loading })}
    label={getLabel(loading, label ?? '')}
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...args}
  />
);

export const PrimarySecondaryGreen = ({ loading, label, size = 'large', type, className, ...args }: ZLButtonProps) => (
  <Button
    type={type}
    size={size}
    className={clsx(style.secondaryGreen, className, style[size], { [style.loading]: loading })}
    label={getLabel(loading, label)}
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...args}
  />
);

export const LandingPrimary = ({ className, children, type = 'button', ...rest }: HTMLAttributes<HTMLButtonElement>) => (
  // eslint-disable-next-line react/button-has-type, react/jsx-props-no-spreading
  <button type={type} className={clsx(style.landingPrimary, className)} {...rest}>
    {children}
  </button>
);

export const LandingSecondary = ({ className, children, type = 'button', ...rest }: HTMLAttributes<HTMLButtonElement>) => (
  // eslint-disable-next-line react/button-has-type, react/jsx-props-no-spreading
  <button type={type} className={clsx(style.landingSecondary, className)} {...rest}>
    {children}
  </button>
);
