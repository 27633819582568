import Loader from 'Components/LoaderModal';
import Dialog from 'Components/modals/Dialog';
import { format } from 'date-fns';
import { useMemo } from 'preact/hooks';
import { useDispatch } from 'react-redux';
import { buildURL } from 'Shared/fetch';
import useFetch from 'use-http';

import { useSelector } from '@/redux/helper';
import { setSubscriptionData } from '@/redux/slices/user/actions';

const defaultText =
  'By $RENEW_OR_RESUME, you will be charged for the $NEW_PLAN pass on $VALID_UNTIL after your current pass expires.';
const suggestToCancelDefaultText =
  'You should cancel your current $CURRENT_PLAN subscription before you switch to $NEW_PLAN plan.';
const defaultTitle = 'Payment Notice';

const UpdateSubscriptionDialog = ({ action, planId, priceId, show, interval, onCancelHandler, title }) => {
  const currentSubscriptionStatus = useSelector((state) => state.user.subscription);

  const dispatch = useDispatch();
  const { post, response, loading } = useFetch(buildURL(), { credentials: 'include', cachePolicy: 'no-cache' });

  // is the new plan that user is switching to  is a lifetime plan?
  const isLookingForLifetimePlan = useMemo(() => title?.toLowerCase().includes('lifetime'), [title]);
  const isResuming = useMemo(() => !!currentSubscriptionStatus.resumeDate, [currentSubscriptionStatus.resumeDate]);

  const renewSubscription = () =>
    post(`/users/subscription/renew`, {
      isResumeByUser: true,
    });

  const updateSubscription = () => post(`/users/subscription/${planId}/${priceId}`);

  const onConfirmHandler = async () => {
    try {
      let data;
      if (['renew', 'resume'].includes(action.toLowerCase())) {
        data = await renewSubscription();
      } else if (!isLookingForLifetimePlan) {
        data = await updateSubscription();
      }

      if (response.ok) {
        const { subscriptionStatus } = data;
        dispatch(setSubscriptionData(subscriptionStatus));
        onCancelHandler();
      }
    } catch (err) {
      console.error(`Error trying to perform subscription ${action}`, err.message);
    }
  };

  const validUntilReadable = currentSubscriptionStatus?.validUntil
    ? format(
        typeof currentSubscriptionStatus.validUntil === 'string'
          ? new Date(currentSubscriptionStatus.validUntil)
          : currentSubscriptionStatus.validUntil,
        'MMMM dd, yyyy',
      )
    : null;

  const dialogText = useMemo(
    () =>
      defaultText
        .replace('$VALID_UNTIL', validUntilReadable)
        .replace('$CURRENT_PLAN', 'annual')
        .replace('$NEW_PLAN', interval === 'month' ? 'monthly' : 'annual')
        .replace('$RENEW_OR_RESUME', isResuming ? 'resuming' : 'renewing'),
    [validUntilReadable, interval, isResuming],
  );
  const suggestToCancelFinalText = useMemo(
    () =>
      suggestToCancelDefaultText
        .replace('$CURRENT_PLAN', currentSubscriptionStatus?.productName)
        .replace('$NEW_PLAN', title),
    [currentSubscriptionStatus?.productName, title],
  );

  if (!show) {
    return null;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {isLookingForLifetimePlan &&
      !currentSubscriptionStatus.cancelAt &&
      currentSubscriptionStatus.active &&
      currentSubscriptionStatus.source === 'stripe' ? (
        <Dialog
          text={suggestToCancelFinalText}
          title={defaultTitle}
          buttonLabel="close"
          onConfirm={onCancelHandler}
          onCancel={onCancelHandler}
        />
      ) : (
        <Dialog
          text={dialogText}
          title={defaultTitle}
          buttonLabel={action}
          onConfirm={onConfirmHandler}
          onCancel={onCancelHandler}
        />
      )}
    </>
  );
};

export default UpdateSubscriptionDialog;
