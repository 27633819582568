import { faSearch, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import HabitatStatus from 'Components/HabitatCard/HabitatStatus';
import { differenceInMinutes } from 'date-fns';
import { Box, Grommet, grommet, Image, TextInput } from 'grommet';
import { deepMerge } from 'grommet/utils';
import { chain } from 'lodash-es';
import { h } from 'preact';
import { memo } from 'preact/compat';
import { useCallback, useMemo, useRef, useState } from 'preact/hooks';
import { useDispatch } from 'react-redux';
import { buildURL } from 'Shared/fetch';
import useFetch from 'use-http';

import HabitatLink from '@/components/HabitatLink';
import { routeToHabitat } from '@/components/HabitatLink/utils';
import zoolifeTheme from '@/grommetTheme';
import { useWindowResize } from '@/hooks';
import { setHabitats } from '@/redux/actions';
import { useSelector } from '@/redux/helper';
import { createSortedHabitatsSelector } from '@/redux/selectors/habitats';

import style from './style.scss';

const theme = deepMerge(grommet, zoolifeTheme, {
  global: {
    drop: {
      shadowSize: 'medium',
      extend: `
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        overflow: hidden;
      `,
    },
  },
  button: {
    extend: 'border-radius: 0;',
  },
});

/**
 * Function for rendering a search component with various functionalities.
 *
 * @param {Object} props - The properties object.
 * @param {string} [props.className]
 * @param {'left' | 'right'} [props.iconsPosition]
 * @param {boolean} [props.switchableSearch]
 * @param {boolean} [props.searchShow]
 * @param {(val: boolean) => void} [props.setSearchShow]
 * @param {() => void} [props.onClickSuggestion]
 * @return {JSX.Element} The rendered search component.
 */
const Search = ({
  className,
  iconsPosition = 'right',
  switchableSearch = false,
  searchShow = true,
  setSearchShow,
  onClickSuggestion,
}) => {
  const dispatch = useDispatch();
  const selectSortedHabitats = useMemo(() => createSortedHabitatsSelector(), []);
  const sortedHabitats = useSelector(selectSortedHabitats);
  const subscription = useSelector((state) => state.user.subscription);
  const role = useSelector((state) => state.user.role);
  const [suggestionOpen, setSuggestionOpen] = useState(false);
  const [value, setValue] = useState('');
  const boxRef = useRef();
  const lastFetchRef = useRef();
  const { width: windowWidth } = useWindowResize();
  const { get, response } = useFetch(buildURL('/habitats/all'), {
    credentials: 'include',
    cachePolicy: 'no-cache',
  });

  const onSuggestionsOpen = useCallback(() => setSuggestionOpen(true), []);
  const onSuggestionsClose = useCallback(() => setSuggestionOpen(false), []);

  const onSearchFocus = async () => {
    const now = new Date();
    // refresh habitats on focus if data is older than a minute
    if (!lastFetchRef.current || differenceInMinutes(now, lastFetchRef.current) > 1) {
      lastFetchRef.current = now;
      await get();
      if (response.ok) {
        dispatch(setHabitats(response.data.habitats));
      }
    }
  };

  const handleSearchShow = () => {
    setSearchShow?.(true);
    setTimeout(() => {
      boxRef.current.querySelector('input').focus();
    });
  };

  const onChange = useCallback(({ target }) => {
    setValue(target.value);
  }, []);

  const onHabitatClick = useCallback(() => {
    setValue('');
    onClickSuggestion?.();
  }, [onClickSuggestion]);

  const suggestions = useMemo(() => {
    const input = value.trim().toLowerCase();
    const isGeneralUser = ['user', 'vip'].includes(role);
    const freeHabitats = new Set(subscription.freeHabitats ?? []);

    return chain(sortedHabitats)
      .map((habitat) => {
        if (!input) return [habitat, 0];

        const { title, animal, hidden, zoo, keywords } = habitat;
        if (hidden) {
          return null;
        }
        if (title?.toLowerCase().includes(input)) {
          return [habitat, 1];
        }
        if (animal?.toLowerCase().includes(input)) {
          return [habitat, 2];
        }
        if (zoo.name.toLowerCase().includes(input)) {
          return [habitat, 3];
        }
        // we converted the keywords to lower case when we saved them in the DB
        if (keywords?.some((keyword) => keyword.includes(input))) {
          return [habitat, 4];
        }
        return null;
      })
      .filter((data) => data != null)
      .sort((a, b) => a[1] - b[1]) // sort by sortingOrder
      .map(([{ _id, profileImage, title, isStreamOn, liveTalk, slug, zoo }], ind, list) => ({
        value: _id,
        onClick: () => {
          routeToHabitat({ id: _id, slug, zooSlug: zoo.slug });
          onHabitatClick();
        },
        label: (
          <HabitatLink className={style.suggestion} habitatId={_id} slug={slug} zooSlug={zoo.slug} onClick={onHabitatClick}>
            <Box
              direction="row"
              pad="small"
              align="center"
              gap="small"
              round={false}
              border={ind < list.length - 1 ? 'bottom' : undefined}
            >
              <Box width="30px" height="30px">
                <Image
                  src={profileImage}
                  style={{ borderRadius: '100%' }}
                  className={clsx(style.img, {
                    [style.liveTalk]: windowWidth < 460 && liveTalk,
                    [style.online]: windowWidth < 460 && isStreamOn,
                    [style.offline]: windowWidth < 460 && !isStreamOn,
                  })}
                />
              </Box>
              <span className={style.title}>{title}</span>
              <HabitatStatus
                online={isStreamOn}
                liveTalk={liveTalk}
                free={!subscription.active && isGeneralUser && freeHabitats.has(_id)}
                className={style.habitatStatusMarginRight}
              />
            </Box>
          </HabitatLink>
        ),
      }))
      .value();
  }, [value, role, sortedHabitats, onHabitatClick, windowWidth, subscription.active, subscription.freeHabitats]);

  const icons = switchableSearch ? (
    <Box onClick={() => setSearchShow?.(false)} className={style.searchClickIndicator}>
      <FontAwesomeIcon icon={faTimes} color="var(--grey)" style={{ width: '14px' }} />
    </Box>
  ) : (
    <Box onClick={() => boxRef.current.querySelector('input').focus()} className={style.searchClickIndicator}>
      <FontAwesomeIcon icon={faSearch} color="var(--grey)" style={{ width: '14px' }} />
    </Box>
  );

  /** @type {import('grommet').TextInputProps['onSelect']} */
  const onSelect = (e) => {
    e.suggestion.onClick?.();
    boxRef.current.querySelector('input').blur();
  };

  return (
    <Grommet theme={theme} className="flex-grow">
      {searchShow && (
        <Box
          ref={boxRef}
          direction="row"
          align="center"
          round="20px"
          className={clsx(style.searchWrapper, className)}
          pad={{ horizontal: 'small' }}
          elevation={suggestionOpen ? 'medium' : undefined}
          border={{
            side: 'all',
            color: suggestionOpen ? 'transparent' : '#CDCDCD',
          }}
          style={
            suggestionOpen
              ? {
                  borderBottomLeftRadius: '0px',
                  borderBottomRightRadius: '0px',
                }
              : undefined
          }
        >
          {iconsPosition === 'left' && icons}
          <TextInput
            dropTarget={boxRef.current}
            placeholder="Search for an animal..."
            plain
            suggestions={suggestions}
            value={value}
            onChange={onChange}
            onSuggestionsOpen={onSuggestionsOpen}
            onSuggestionsClose={onSuggestionsClose}
            onFocus={onSearchFocus}
            onSelect={onSelect}
            onKeyDown={(e) => {
              // Only trigger when there is only one result and the user presses enter
              // If there are more than one result, the user can use the up and down arrows to select the desired result
              if (e.key === 'Enter' && suggestions.length === 1) {
                [e.suggestion] = suggestions;
                onSelect(e);
              }
            }}
            style={{ padding: '7px', fontWeight: '500' }}
            dropHeight="medium"
          />
          {iconsPosition === 'right' && icons}
        </Box>
      )}
      {!searchShow && (
        <Box onClick={handleSearchShow} className={style.searchClickIndicator} align="end">
          <FontAwesomeIcon icon={faSearch} color="var(--grey)" style={{ width: '14px' }} />
        </Box>
      )}
    </Grommet>
  );
};

export default memo(Search);
