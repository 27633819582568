/* eslint-disable react/jsx-props-no-spreading */
import { faBook } from '@fortawesome/pro-regular-svg-icons';
import { faGift } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { Box, Card, CardBody, CardHeader, Heading, Text } from 'grommet';
import { useSelector } from 'react-redux';

import { useIsMobileSize } from '../../../../hooks';
import { OutlineButton, PrimaryButton } from '../../../Buttons';
import style from './style.scss';

const getMarginBottom = (price) => {
  if (price === 190) {
    return '0px';
  }

  if (price === 699) {
    return '30px';
  }

  return '10px';
};

// used in src/components/Subscription/SubscriptionManager/index.js
const PlanCard = ({
  planId,
  priceId,
  planName,
  planPrice,
  planType,
  color,
  buttonColor,
  discount,
  onClickHandler,
  disabled,
  buttonLabel = 'Select',
  benefitText,
  originalPrice,
  benefitTitle,
  planSubtitle,
  currencySymbol,
  showDetailsModal,
  openDetailsModalHandler,
}) => {
  const isMobileSize = useIsMobileSize();
  const maxWidth = isMobileSize ? '330px' : '200px';
  const maxHeight = isMobileSize ? '130px' : '300px';
  return (
    <div>
      <Box pad={{ vertical: '10px' }} className={style.planCard} margin={{ bottom: isMobileSize ? '0px' : '20px' }}>
        {discount && (
          <Box
            background="var(--blueLight)"
            align="center"
            justify="center"
            className={clsx(style.discountTag, { [style.mobile]: isMobileSize })}
          >
            <Text weight={900} size="15px" color="var(--charcoal)">
              {discount}
            </Text>
            <Text size="9px" weight={400} color="var(--charcoal)">
              discount
            </Text>
          </Box>
        )}
        <Card
          background={{ color: 'white' }}
          width={{ min: maxWidth, max: maxWidth }}
          height={{ min: maxHeight, max: maxHeight }}
          direction={isMobileSize ? 'row' : 'column'}
          className={style.cardShadow}
        >
          <CardHeader
            background={{ color }}
            width={{ min: isMobileSize ? '180px' : '200px', max: isMobileSize ? '180px' : '150px' }}
            height={{ min: !isMobileSize ? '130px' : '0px' }}
            justify={planPrice === 'FREE' ? 'center' : 'start'}
          >
            <Box
              fill
              textAlign="center"
              alignSelf={planPrice === 'FREE' ? 'center' : 'start'}
              pad={{ top: planPrice === 'FREE' ? '0px' : '20px' }}
              justify={planPrice === 'FREE' ? 'center' : 'start'}
              margin={{
                bottom: isMobileSize ? '0px' : getMarginBottom(planPrice),
              }}
            >
              {(planType === 'Gift' || planType === 'bundleUser') && (
                <Box direction="row" align="center" justify="center">
                  <FontAwesomeIcon icon={faGift} style={{ height: '14px' }} />
                  <Text
                    alignSelf="center"
                    size="xlarge"
                    weight={800}
                    margin={{ left: '8px' }}
                    style={{ lineHeight: '13px' }}
                  >
                    zoolife
                  </Text>
                </Box>
              )}
              {planName === 'Class Pass' && (
                <Box direction="row" align="center" justify="center">
                  <FontAwesomeIcon icon={faBook} />
                  <Text alignSelf="center" size="xlarge" weight={800} margin={{ left: '8px' }}>
                    {`${planName}`}
                  </Text>
                </Box>
              )}
              {planName !== 'Class Pass' && (
                <Text alignSelf="center" size="xlarge" weight={800}>
                  {planName ? `${planName}` : ''}
                </Text>
              )}
              {planType !== 'Gift' && planType !== 'bundleUser' && (
                <Box direction="row" justify="center" align="center">
                  {planPrice === 'FREE' ? (
                    <Heading
                      level="2"
                      // eslint-disable-next-line no-nested-ternary
                      margin={{ top: !isMobileSize ? (planPrice === 'FREE' ? '0px' : '12px') : '0px', bottom: '0' }}
                    >
                      {planPrice}
                    </Heading>
                  ) : (
                    <>
                      <Heading
                        level={currencySymbol.length === 1 ? '3' : '5'}
                        weight={currencySymbol.length === 1 ? '' : 'bold'}
                        // eslint-disable-next-line no-nested-ternary
                        margin={{
                          top: !isMobileSize ? (planPrice === 'FREE' ? '0px' : '12px') : '0px',
                          bottom: '0',
                          right: currencySymbol.length === 1 ? '2px' : '7px',
                        }}
                      >
                        {currencySymbol}
                      </Heading>
                      <Heading
                        alignSelf="center"
                        level="2"
                        // eslint-disable-next-line no-nested-ternary
                        margin={{ top: !isMobileSize ? (planPrice === 'FREE' ? '0px' : '12px') : '0px', bottom: '0' }}
                      >
                        {planPrice / 100}
                      </Heading>
                      {['month', 'year'].includes(planType) && (
                        <Text alignSelf="end" margin={{ bottom: '2px', left: 'xxsmall' }}>
                          {`/${planType}`}
                        </Text>
                      )}
                    </>
                  )}
                </Box>
              )}
              {(planType === 'Gift' || planType === 'bundleUser') && (
                <Box direction="row" justify="center" align="center">
                  <Heading
                    // eslint-disable-next-line no-nested-ternary
                    margin={{ top: !isMobileSize ? '12px' : '0px', bottom: '0' }}
                    alignSelf="center"
                    level="2"
                  >
                    {planSubtitle}
                  </Heading>
                  {['month', 'year'].includes(planType) && (
                    <Text alignSelf="end" margin={{ bottom: '2px' }}>
                      {`/${planType}`}
                    </Text>
                  )}
                </Box>
              )}
              {originalPrice && (
                <Box align="center" margin={{ top: '3px' }}>
                  <Text
                    size={isMobileSize ? '16px' : '20px'}
                    style={{ lineHeight: '20px', textDecorationLine: 'line-through' }}
                  >
                    {`${currencySymbol} ${originalPrice / 100}`}
                  </Text>
                </Box>
              )}
              {showDetailsModal && (
                <Box pad="3px" justify="center" align="center" margin={{ top: '0px' }} onClick={openDetailsModalHandler}>
                  <Text color="#376CDE" size="xlarge" style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                    Learn More
                  </Text>
                </Box>
              )}
            </Box>
          </CardHeader>
          <CardBody
            width={{ min: isMobileSize ? '150px' : '200px', max: isMobileSize ? '150px' : '150px' }}
            pad={{ horizontal: isMobileSize ? '10px' : '0px' }}
          >
            <Box
              margin="auto"
              align="center"
              justify="center"
              // eslint-disable-next-line no-nested-ternary
              pad={{ horizontal: isMobileSize ? '0px' : planPrice === 199 ? 'medium' : '10px' }}
            >
              <Text size="medium" style={{ textTransform: 'capitalize' }} weight={700}>
                {benefitTitle}
              </Text>
              <Text size={isMobileSize ? 'medium' : 'xlarge'} textAlign="center" margin={{ top: '5px' }}>
                {benefitText}
              </Text>
            </Box>
            <Box justify="end" direction="column" pad="6px 6px 14px">
              {buttonLabel === 'Cancel' && (
                <OutlineButton
                  alignSelf="center"
                  disabled={disabled}
                  label={buttonLabel}
                  onClick={() => onClickHandler(planId, priceId)}
                  size={isMobileSize ? 'medium' : 'large'}
                />
              )}
              {buttonLabel !== 'Cancel' && (
                <PrimaryButton
                  alignSelf="center"
                  disabled={disabled}
                  label={buttonLabel}
                  onClick={() => onClickHandler(planId, priceId)}
                  size={isMobileSize ? 'medium' : 'large'}
                  style={{ backgroundColor: buttonColor, color: 'var(--charcoal)' }}
                />
              )}
            </Box>
          </CardBody>
        </Card>
      </Box>
    </div>
  );
};

const VariantB = ({
  planId,
  priceId,
  planName,
  planPrice,
  planType,
  color,
  buttonColor,
  discount,
  onClickHandler,
  disabled,
  buttonLabel = 'Select',
  benefitText,
  originalPrice,
  benefitTitle,
  planSubtitle,
  currencySymbol,
  showDetailsModal,
  openDetailsModalHandler,
}) => {
  const isMobileSize = useIsMobileSize();
  const maxWidth = isMobileSize ? '330px' : '200px';
  const maxHeight = isMobileSize ? '140px' : '300px';
  const getVariantBTexts = (planType) => {
    const PaidBenefit = (
      <ul style={{ margin: 0, padding: 0 }}>
        <li>Access all animals</li>
        <li>Unlimited camera control</li>
        <li>Unlimited photos & clips</li>
      </ul>
    );
    switch (planType.toLowerCase()) {
      case 'month':
        return {
          benefitList: PaidBenefit,
          benefitRecurring: 'Recurring Monthly',
        };
      case 'year':
        return {
          benefitList: PaidBenefit,
          benefitRecurring: 'Recurring Annually',
        };
      case 'free':
        return {
          benefitList: (
            <ul style={{ margin: 0, padding: 0 }}>
              <li>Limited animal habitats</li>
              <li>Limited camera control</li>
              <li>Limited photos & clips</li>
            </ul>
          ),
          benefitRecurring: isMobileSize ? '' : <>&nbsp;</>,
        };
      default:
        return { benefitList: benefitTitle, benefitRecurring: benefitText };
    }
  };

  return (
    <div>
      <Box pad={{ vertical: '10px' }} className={style.planCard} margin={{ bottom: isMobileSize ? '0px' : '20px' }}>
        {discount && (
          <Box
            background="var(--blueLight)"
            align="center"
            justify="center"
            className={clsx(style.discountTag, { [style.mobile]: isMobileSize })}
          >
            <Text weight={900} size="15px" color="var(--charcoal)">
              {discount}
            </Text>
            <Text size="9px" weight={400} color="var(--charcoal)">
              discount
            </Text>
          </Box>
        )}
        <Card
          background={{ color: 'white' }}
          width={{ min: maxWidth, max: maxWidth }}
          height={{ min: maxHeight, max: maxHeight }}
          direction={isMobileSize ? 'row' : 'column'}
          className={style.cardShadow}
        >
          <CardHeader
            background={{ color }}
            width={{ min: isMobileSize ? '180px' : '200px', max: isMobileSize ? '180px' : '150px' }}
            height={{ min: !isMobileSize ? '130px' : '0px' }}
            justify={planPrice === 'FREE' ? 'center' : 'start'}
          >
            <Box
              fill
              textAlign="center"
              alignSelf={planPrice === 'FREE' ? 'center' : 'start'}
              pad={{ top: planPrice === 'FREE' || isMobileSize ? '0px' : '20px' }}
              justify={planPrice === 'FREE' || isMobileSize ? 'center' : 'start'}
              margin={{
                bottom: isMobileSize ? '0px' : getMarginBottom(planPrice),
              }}
            >
              {(planType === 'Gift' || planType === 'bundleUser') && (
                <Box direction="row" align="center" justify="center">
                  <FontAwesomeIcon icon={faGift} style={{ height: '14px' }} />
                  <Text
                    alignSelf="center"
                    size="xlarge"
                    weight={800}
                    margin={{ left: '8px' }}
                    style={{ lineHeight: '13px' }}
                  >
                    zoolife
                  </Text>
                </Box>
              )}
              {planName === 'Class Pass' && (
                <Box direction="row" align="center" justify="center">
                  <FontAwesomeIcon icon={faBook} />
                  <Text alignSelf="center" size="xlarge" weight={800} margin={{ left: '8px' }}>
                    {`${planName}`}
                  </Text>
                </Box>
              )}
              {planName !== 'Class Pass' && (
                <Text alignSelf="center" size="xlarge" weight={800}>
                  {planName ? `${planName}` : ''}
                </Text>
              )}
              {planType !== 'Gift' && planType !== 'bundleUser' && (
                <Box direction="row" justify="center" align="center">
                  {planPrice === 'FREE' ? (
                    <Heading
                      level="3"
                      // eslint-disable-next-line no-nested-ternary
                      margin={{ top: !isMobileSize ? (planPrice === 'FREE' ? '0px' : '12px') : '0px', bottom: '0' }}
                    >
                      {planPrice}
                    </Heading>
                  ) : (
                    <>
                      <Heading
                        level={currencySymbol.length === 1 ? '3' : '5'}
                        weight={currencySymbol.length === 1 ? '' : 'bold'}
                        // eslint-disable-next-line no-nested-ternary
                        margin={{
                          top: !isMobileSize ? (planPrice === 'FREE' ? '0px' : '12px') : '0px',
                          bottom: '0',
                          right: currencySymbol.length === 1 ? '2px' : '7px',
                        }}
                      >
                        {currencySymbol}
                      </Heading>
                      <Heading
                        alignSelf="center"
                        level="2"
                        // eslint-disable-next-line no-nested-ternary
                        margin={{ top: !isMobileSize ? (planPrice === 'FREE' ? '0px' : '12px') : '0px', bottom: '0' }}
                      >
                        {planPrice / 100}
                      </Heading>
                      {['month', 'year'].includes(planType) && (
                        <Text alignSelf="end" margin={{ bottom: '2px', left: 'xxsmall' }}>
                          {`/${planType}`}
                        </Text>
                      )}
                    </>
                  )}
                </Box>
              )}
              {(planType === 'Gift' || planType === 'bundleUser') && (
                <Box direction="row" justify="center" align="center">
                  <Heading
                    // eslint-disable-next-line no-nested-ternary
                    margin={{ top: !isMobileSize ? '12px' : '0px', bottom: '0' }}
                    alignSelf="center"
                    level="2"
                  >
                    {planSubtitle}
                  </Heading>
                  {['month', 'year'].includes(planType) && (
                    <Text alignSelf="end" margin={{ bottom: '2px' }}>
                      {`/${planType}`}
                    </Text>
                  )}
                </Box>
              )}
              {originalPrice && (
                <Box align="center" margin={{ top: '3px' }}>
                  <Text
                    size={isMobileSize ? '16px' : '20px'}
                    style={{ lineHeight: '20px', textDecorationLine: 'line-through' }}
                  >
                    {`${currencySymbol} ${originalPrice / 100}`}
                  </Text>
                </Box>
              )}
              {showDetailsModal && (
                <Box pad="3px" justify="center" align="center" margin={{ top: '0px' }} onClick={openDetailsModalHandler}>
                  <Text color="#376CDE" size="xlarge" style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                    Learn More
                  </Text>
                </Box>
              )}
            </Box>
          </CardHeader>
          <CardBody
            width={{ min: isMobileSize ? '160px' : '200px', max: isMobileSize ? '150px' : '200px' }}
            pad={{ horizontal: isMobileSize ? '10px' : '0px' }}
          >
            <Box
              margin="auto"
              align="center"
              justify="center"
              // eslint-disable-next-line no-nested-ternary
              pad={{ horizontal: isMobileSize ? '10px' : planPrice === 199 ? 'medium' : '10px' }}
            >
              <Text size={isMobileSize ? 'small' : 'medium'} weight={400} style={{ textTransform: 'capitalize' }}>
                {getVariantBTexts(planType ?? planPrice).benefitList}
              </Text>
              <Text size={isMobileSize ? 'medium' : 'xlarge'} textAlign="center" margin={{ top: '10px' }}>
                {getVariantBTexts(planType ?? planPrice).benefitRecurring}
              </Text>
            </Box>
            <Box justify="end" direction="column" pad="6px 6px 14px">
              {buttonLabel === 'Cancel' && (
                <OutlineButton
                  alignSelf="center"
                  disabled={disabled}
                  label={buttonLabel}
                  onClick={() => onClickHandler(planId, priceId)}
                  size={isMobileSize ? 'medium' : 'large'}
                />
              )}
              {buttonLabel !== 'Cancel' && (
                <PrimaryButton
                  alignSelf="center"
                  disabled={disabled}
                  label={buttonLabel}
                  onClick={() => onClickHandler(planId, priceId)}
                  size={isMobileSize ? 'medium' : 'large'}
                  style={{ backgroundColor: buttonColor, color: 'var(--charcoal)' }}
                />
              )}
            </Box>
          </CardBody>
        </Card>
      </Box>
    </div>
  );
};

const PlanCardWithVariant = (props) => {
  const dailyCredits = useSelector((state) => state.user.dailyCredits);
  const active = useSelector((state) => state.user.subscription?.active);
  return <>{!active && dailyCredits?.inUse ? <VariantB {...props} /> : <PlanCard {...props} />}</>;
};

export default PlanCardWithVariant;
