import { uuid } from 'zl-shared/dist/lib';

const DEFAULT_STORAGE_KEY = 'zlDeviceId';

const getDeviceId = (storageKey = DEFAULT_STORAGE_KEY) => {
  const deviceId = localStorage.getItem(storageKey);
  if (deviceId) {
    return deviceId;
  }

  const newDeviceId = `web:${uuid()}`;
  localStorage.setItem(storageKey, newDeviceId);
  return newDeviceId;
};

export default getDeviceId;
