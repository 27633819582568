import { Box } from 'grommet';
import { cloneElement, toChildArray } from 'preact';

const PageWrapper = ({ children, ...props }) => {
  const childrenWithProps = toChildArray(children).map((child) => child && cloneElement(child, props));

  return (
    <Box fill className="pageWrapper">
      <Box fill overflow="auto">
        <Box
          fill
          width={{ max: 'var(--maxWidth)', min: '350px' }}
          height="100%"
          margin={{ horizontal: 'auto' }}
          background="white"
        >
          {childrenWithProps}
        </Box>
      </Box>
    </Box>
  );
};

export default PageWrapper;
