import { PropsWithChildren } from 'preact/compat';
import { AnalyticsProvider } from 'zl-shared/dist/video-analytics';
import getVideoPlayer from 'zl-shared/dist/video-analytics/players/web';

const VideoAnalyticsProvider = ({ children }: PropsWithChildren) => {
  return (
    <AnalyticsProvider defaultTimeInterval={15000} defaultGetVideoPlayer={getVideoPlayer}>
      {children}
    </AnalyticsProvider>
  );
};

export default VideoAnalyticsProvider;
