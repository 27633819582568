import { useRouter } from 'preact-router';
import { useDispatch } from 'react-redux';

import { useSelector } from '@/redux/helper';

import MembersOnlyWarningModal from '..';
import profileImage from '../assets/profile.webp';
import { setMembersOnlyWarningModalState } from './actions';

const ConnectedMembersOnlyWarningModal = () => {
  const dispatch = useDispatch();
  const [{ path }] = useRouter();
  const logged = useSelector((state) => state.user.logged);
  const { habitat, redirectOnDismiss } = useSelector((state) => state.modals.membersOnlyWarningModal);
  const onDismiss = () => dispatch(setMembersOnlyWarningModalState(null));

  return !habitat ? null : (
    <MembersOnlyWarningModal profilePicture={profileImage} onClickOutside={redirectOnDismiss ? undefined : onDismiss}>
      <div>
        <MembersOnlyWarningModal.Title className="px-6">*gasp*, this is for members-only!</MembersOnlyWarningModal.Title>
        <MembersOnlyWarningModal.Content>
          Unlock full access to all of zoolife.
          <br />
          50% of funds goes to help the animals.
        </MembersOnlyWarningModal.Content>
      </div>
      <MembersOnlyWarningModal.ButtonsContainer>
        <a
          href={logged ? '/plans' : '/prices'}
          className="preflight preflight-a rounded-full bg-primary-green p-4 text-center text-base font-medium leading-none text-white hover:bg-secondary-green"
          onClick={onDismiss}
        >
          Become a member
        </a>
        <a
          href="/habitats"
          className="preflight preflight-a rounded-full border border-primary-green p-4 text-center text-base font-medium leading-none text-primary-green hover:bg-beige-light"
          onClick={onDismiss}
        >
          Explore as a guest
        </a>
        <div className="p-4 text-center">
          {redirectOnDismiss ? (
            <a
              href="/home"
              className="preflight preflight-a inline-block border-b border-current text-base font-medium leading-none text-grey-3 hover:text-grey-1"
              onClick={onDismiss}
            >
              Back to home page
            </a>
          ) : (
            <button
              type="button"
              className="preflight preflight-button inline-block border-b border-current text-base font-medium leading-none text-grey-3 hover:text-grey-1"
              onClick={onDismiss}
            >
              {path === '/home' ? 'Back to home page' : 'Dismiss'}
            </button>
          )}
        </div>
      </MembersOnlyWarningModal.ButtonsContainer>
    </MembersOnlyWarningModal>
  );
};

export default ConnectedMembersOnlyWarningModal;
