import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryButton } from 'Components/Buttons';
import { Box, Button, Heading, Layer, Text } from 'grommet';
import { h } from 'preact';

const DialogModal = ({ title, text, buttonLabel, onCancel, onConfirm }) => (
  <Layer position="center" onClickOutside={onCancel} onEsc={onCancel} background="white">
    <Box width="400px">
      <Box
        direction="row"
        align="center"
        as="header"
        justify="center"
        overflow="hidden"
        style={{ position: 'relative' }}
        fill="horizontal"
      >
        <Heading level="3" color="var(--charcoal)" margin={{ top: '52px', bottom: '20px' }}>
          {title}
        </Heading>
        <Button
          plain
          margin="small"
          onClick={onCancel}
          icon={<FontAwesomeIcon size="lg" color="--var(grey)" icon={faTimes} />}
          style={{ position: 'absolute', right: 0, top: 0 }}
        />
      </Box>
      <Box justify="center" align="center" pad={{ vertical: '0', horizontal: 'large' }}>
        <Text textAlign="center" size="16px" color="var(--charcoalLight)" style={{ lineHeight: '22px' }}>
          {text}
        </Text>

        <Box width={{ min: '140px' }} margin={{ top: '30px', bottom: '52px' }}>
          <PrimaryButton size="large" label={buttonLabel} onClick={onConfirm} />
        </Box>
      </Box>
    </Box>
  </Layer>
);

export default DialogModal;
