
		import Async from "../../../node_modules/@preact/async-loader/async.js";

		function load(cb) {
			require.ensure([], function (require) {
				var result = require("!!../../../node_modules/babel-loader/lib/index.js??ref--4-0!../../../node_modules/source-map-loader/dist/cjs.js!./index.tsx");
				typeof cb === 'function' && cb(result);
			}, "route-adminAnnouncements");
		}

		export default Async(load);
	