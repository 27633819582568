import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import RoundCount from 'Components/RoundCount';

import { useShouldDisableJSRouting } from '@/components/providers/DisableJSRoutingProvider';

/**
 * Renders a navigation item component.
 *
 * @param {object} props - The properties for the navigation item.
 * @param {string} [props.url] - The URL for the navigation item.
 * @param {string} props.text - The text for the navigation item.
 * @param {import('@fortawesome/fontawesome-svg-core').IconDefinition} [props.icon] - The icon for the navigation item.
 * @param {import('preact/compat').ReactElement} [props.customIcon] - The custom icon for the navigation item.
 * @param {function} [props.onClick] - The click event handler for the navigation item.
 * @param {number} [props.count=0] - The count for the navigation item.
 * @param {string} [props.target] - The target for the navigation item.
 * @param {boolean} [props.avoidInnerPadding] - Removes the padding on the inner element.
 * @return {import('preact/compat').ReactElement} The rendered navigation item component.
 */
const NavItem = ({ url, text, icon, customIcon, onClick, count = 0, target, avoidInnerPadding }) => (
  <div className={clsx('text-sm font-medium text-grey-3 hover:text-grey-1', !avoidInnerPadding && 'px-4 pt-3')}>
    <a
      data-native={useShouldDisableJSRouting()}
      className="preflight preflight-a relative flex items-center gap-2"
      href={url}
      onClick={onClick}
      target={target}
    >
      <span className={!avoidInnerPadding ? 'inline-block w-5' : undefined}>
        {customIcon || <FontAwesomeIcon icon={icon} />}
      </span>
      {count > 0 && <RoundCount count={count} />}
      <span>{text}</span>
    </a>
  </div>
);

export default NavItem;
