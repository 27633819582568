import { cn } from '@/lib/utils';

const roundNumber = (length, max) => (length == null ? null : length > max ? `${max}+` : length.toString());

const RoundCount = ({ count, round = true, max = 9, className }) => {
  return (
    <span
      className={cn(
        'absolute -right-3 -top-2 z-[2] flex h-4 min-w-4 items-center justify-center rounded-full bg-primary-green p-0.5 text-[10px]/none text-white antialiased',
        className,
      )}
    >
      {round ? roundNumber(count, max) : count}
    </span>
  );
};

export default RoundCount;
