import { PrimaryButton } from 'Components/Buttons';
import Loader from 'Components/Loader';
import CloseButton from 'Components/modals/CloseButton';
import { Layer, Text } from 'grommet';
import { useEffect, useState } from 'preact/hooks';
import { route } from 'preact-router';
import { useDispatch, useSelector } from 'react-redux';
import { buildURL } from 'Shared/fetch';
import useFetch from 'use-http';

import { setSubscriptionData } from '@/redux/slices/user/actions';

import style from './style.scss';

const CancelSubscriptionModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const validUntil = useSelector((state) => state.user.subscription.validUntil);

  const [showSuccess, setShowSuccess] = useState(false);
  const { post, loading, response, error } = useFetch(buildURL(), { credentials: 'include', cachePolicy: 'no-cache' });

  useEffect(() => {
    const cancelSubscription = async () => {
      const { subscriptionStatus } = await post('/users/subscription/unsubscribe');

      if (response.ok) {
        dispatch(setSubscriptionData(subscriptionStatus));
        setShowSuccess(true);
      }
    };

    cancelSubscription();
  }, [post, response, dispatch]);

  return (
    <Layer position="center" onClickOutside={onClose} onEsc={onClose} background="white" className={style.modalStyle}>
      <div className={style.cancelSubscriptionContainer}>
        <div>
          <CloseButton varient="grey" onClick={onClose} className={style.close} />
          <h4 className="mb-1 mt-2 text-xl font-bold md:text-2xl">
            {showSuccess ? 'Successful Cancellation' : 'Sorry to see you go!'}
          </h4>
        </div>
        <div className="my-4">
          {!showSuccess ? (
            <Loader />
          ) : (
            <Text textAlign="center" size="xlarge" className={style.text}>
              Your membership will be active until
              {` ${new Date(validUntil).toLocaleDateString()}.`}
            </Text>
          )}
          {error && (
            <Text textAlign="center" size="xlarge" color="var(--red)">
              Something went wrong. Please try again.
            </Text>
          )}
        </div>
        <div className={style.buttonsContainer}>
          <PrimaryButton
            label={showSuccess ? 'Back to Home Page' : 'Close'}
            onClick={() => {
              onClose();
              if (showSuccess) {
                route('/home');
              }
            }}
            disabled={loading || !showSuccess}
          />
        </div>
      </div>
    </Layer>
  );
};

export default CancelSubscriptionModal;
