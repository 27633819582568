import clsx from 'clsx';
import LoaderModal from 'Components/LoaderModal';
import Dialog from 'Components/modals/Dialog';
import { getCurrencySymbol } from 'Components/Subscription/SubscriptionManager/helpers';
import { Box, Text } from 'grommet';
import { isEmpty } from 'lodash-es';
import { memo } from 'preact/compat';
import { useCallback, useEffect, useMemo, useState } from 'preact/hooks';
import { route } from 'preact-router';
import { useDispatch } from 'react-redux';
import { buildURL } from 'Shared/fetch';
import useFetch from 'use-http';

import { loadPage } from '@/helpers';
import { useIsMobileSize } from '@/hooks';
import { useSelector } from '@/redux/helper';
import { setPlans } from '@/redux/slices/plans/actions';
import { setUserData } from '@/redux/slices/user/actions';

import CancelReasonsModal from './CancelReasonsModal';
import CancelSubscriptionModal from './CancelSubscriptionModal';
import ClassPassDetailsModal from './ClassPassDetailsModal';
import PlanCard from './PlanCard';
import style from './style.scss';
import UpdateSubscriptionDialog from './UpdateSubscriptionDialog';

const defaultDialogSettings = {
  show: false,
  planId: null,
  priceId: null,
  action: null,
  interval: null,
};

const EMPTY_PLANS = Object.freeze([]);

// used in embeded
const SubscriptionManager = ({
  showStatus,
  showFreemium,
  isPublicPage,
  showGiftUserPlan = false,
  hiddePlans = ['Day Pass', 'Class Pass'],
  variant,
}) => {
  const dispatch = useDispatch();
  const plans = useSelector((state) => state.plans.plans) ?? EMPTY_PLANS;
  const productId = useSelector((state) => state.user.subscription.productId);
  const validUntil = useSelector((state) => state.user.subscription.validUntil);
  const isSubscriptionActive = useSelector((state) => state.user.subscription.active);
  const scheduledProductId = useSelector((state) => state.user.subscription.scheduledProductId);
  const cancelAt = useSelector((state) => state.user.subscription.cancelAt);
  const canceledAt = useSelector((state) => state.user.subscription.canceledAt);
  const isSmallScreen = useIsMobileSize();
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showCancelReasonModal, setShowCancelReasonModal] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [showClassPassModal, setShowClassPassModal] = useState(false);
  const [userPlanData, setUserPlanData] = useState(null);
  const showCancelButton = useMemo(() => {
    if (!isSubscriptionActive) {
      return false;
    }

    if (isEmpty(plans)) {
      return false;
    }

    // prevent cancelling gift cards and freemium and bundleUser
    if (['freemium', 'giftCard', 'bundleUser'].includes(userPlanData?.type)) {
      return false;
    }

    // prevent canceling a subscription in the past
    if (new Date(validUntil) < new Date()) {
      return false;
    }

    // prevent cancel button from showing when user already canceled
    if (cancelAt) {
      return false;
    }

    const dailyPlans = plans
      .filter(({ interval }) => interval === 'visit')
      .map(({ productId: planProductId }) => planProductId);
    if (dailyPlans.includes(productId)) {
      return false;
    }
    return true;
  }, [productId, plans, validUntil, cancelAt, userPlanData, isSubscriptionActive]);

  const [dialogSettings, setDialogSettings] = useState(defaultDialogSettings);

  const { get, post, loading } = useFetch(buildURL(), { credentials: 'include', cachePolicy: 'no-cache' });

  const fetchPlans = useCallback(async () => {
    const response = await get(`/products${variant ? `?v=${variant}` : ''}`);
    if (response.products) {
      dispatch(setPlans(response.products));
    }
    if (response.user) {
      dispatch(setUserData(response.user));
    }
  }, [get, variant, dispatch]);

  const fetchUserPlan = useCallback(async () => {
    if (showGiftUserPlan) {
      const userPlan = await get('/users/product');
      setUserPlanData(userPlan);
    }
  }, [get, setUserPlanData, showGiftUserPlan]);

  useEffect(() => {
    fetchPlans();
    fetchUserPlan();
  }, [fetchPlans, fetchUserPlan]);

  const goToSignup = useCallback(async (planId, priceId) => {
    loadPage(`/signup?plan=${planId}&price=${priceId}`);
  }, []);

  const checkoutHandler = useCallback(
    async (planId, priceId) => {
      try {
        const { url } = await post(`/checkout/${planId}/${priceId}`);
        loadPage(url);
      } catch (err) {
        console.error('Error trying to start checkout process', err);
        // TODO: display error modal
      }
    },
    [post],
  );

  const openDialogHandler = (action, planId, priceId, interval) => {
    setDialogSettings({
      planId,
      priceId,
      action,
      interval,
      show: true,
    });
  };
  const openDetailsModalHandler = useCallback(() => {
    setShowClassPassModal(true);
  }, []);

  const onCancelHandler = () => {
    setDialogSettings(defaultDialogSettings);
  };

  const handleCancelDialogClose = () => {
    setShowCancelDialog(false);
    route('/map');
  };

  const plansData = useMemo(() => {
    if (!plans.length) {
      return [];
    }

    let currentPlanPrice = null;
    let currentPlanInterval = null;

    const currentPlan = plans.find((plan) => productId === plan.productId);

    if (currentPlan) {
      currentPlanPrice = currentPlan.price;
      currentPlanInterval = currentPlan.interval;
    }

    if (!productId || !isSubscriptionActive || !currentPlan) {
      return plans.map(
        ({
          productId: planProductId,
          priceId,
          color,
          discount,
          interval,
          name,
          order,
          price,
          originalPrice,
          title,
          description,
          currency,
        }) => ({
          planProductId,
          priceId,
          color,
          discount,
          interval,
          name,
          order,
          price,
          currentPlan: false,
          label: 'Select',
          display: true,
          benefitTitle: title,
          benefitText: description,
          clickHandler: () => checkoutHandler(planProductId, priceId),
          originalPrice,
          currency,
        }),
      );
    }

    if (isSubscriptionActive && canceledAt) {
      return plans
        .filter(({ productId: planProductId, name }) => planProductId === productId || name === 'Annual Pass')
        .map(
          ({
            productId: planProductId,
            priceId,
            color,
            discount,
            interval,
            name,
            order,
            price,
            originalPrice,
            title,
            description,
            currency,
          }) => ({
            planProductId,
            priceId,
            color,
            discount,
            interval,
            name,
            order,
            price,
            currentPlan: true,
            label: planProductId === productId ? 'Renew' : 'Upgrade',
            display: true,
            benefitTitle: interval === 'visit' ? '' : title,
            benefitText: interval !== 'visit' ? description : 'Unlock everything for a full day',
            clickHandler: () =>
              openDialogHandler(
                planProductId === productId && name !== 'Annual Pass' ? 'Renew' : 'Upgrade',
                planProductId,
                priceId,
                interval,
              ),
            originalPrice,
            currency,
          }),
        );
    }

    // Subscription is Active, so we will set each available option per plan
    return plans
      .map(
        ({
          productId: planProductId,
          priceId,
          title,
          description,
          color,
          discount,
          interval,
          name,
          order,
          price,
          originalPrice,
          currency,
        }) => {
          const isCurrentPlan = planProductId === productId;
          const isUpgradedPlan = planProductId === scheduledProductId;

          let label;
          let clickHandler;
          let disabled = false;

          if (((isCurrentPlan && isEmpty(scheduledProductId)) || isUpgradedPlan) && interval !== 'visit') {
            label = 'Cancel';
            clickHandler = () => setShowCancelReasonModal(true);
          } else if (currentPlanPrice < price) {
            if (currentPlanInterval === 'visit') {
              label = 'Subscribe';
              clickHandler = () => checkoutHandler(planProductId, priceId);
            } else {
              label = 'Upgrade';
              clickHandler = () => openDialogHandler('Upgrade', planProductId, priceId, interval);
            }
          } else if (!isEmpty(scheduledProductId) && scheduledProductId !== planProductId) {
            label = 'Downgrade';
            clickHandler = () => openDialogHandler('Downgrade', planProductId, priceId, interval);
          } else {
            // Current plan is visit so no action to take
            label = 'Current Pass';
            disabled = true;
          }

          const benefitTitle = interval === 'visit' ? '' : title;
          const benefitText = interval !== 'visit' ? description : 'Unlock everything for a full day';

          return {
            planProductId,
            priceId,
            color,
            discount,
            interval,
            name,
            order,
            price,
            currentPlan: isCurrentPlan,
            label,
            display: (currentPlanInterval !== 'visit' && interval !== 'visit') || currentPlanInterval === 'visit',
            benefitTitle,
            benefitText,
            clickHandler,
            disabled,
            originalPrice,
            currency,
          };
        },
      )
      .filter(({ display }) => display);
  }, [plans, productId, isSubscriptionActive, canceledAt, scheduledProductId, checkoutHandler]);

  return (
    <div className={style.subscriptionManagerContainer}>
      {showClassPassModal && <ClassPassDetailsModal onClose={() => setShowClassPassModal(false)} />}
      {showStatus && showCancelButton && (
        <Box className={style.subscriptionStatus}>
          <Text onClick={() => setShowCancelReasonModal(true)} className={clsx(style.status, style.active)}>
            Looking to cancel your pass? Click here.
          </Text>
        </Box>
      )}

      {showStatus && !isSubscriptionActive && (
        <Box className={style.subscriptionStatus}>
          <Text className={style.status}>You currently have a free account.</Text>
        </Box>
      )}

      {showStatus && canceledAt && new Date(validUntil) > new Date() && (
        <Box className={style.subscriptionStatus}>
          <Text className={style.status}>
            {`You cancelled your membership. The last day of your membership is ${new Date(
              validUntil,
            ).toLocaleDateString()}.`}
          </Text>
        </Box>
      )}

      {showStatus && validUntil && new Date(validUntil) < new Date() && (
        <Box className={style.subscriptionStatus}>
          <Text className={style.status}>
            {`Your membership has expired on ${new Date(
              validUntil,
            ).toLocaleDateString()}, please choose another membership to proceed`}
          </Text>
        </Box>
      )}

      <Box className={style.products} background="#f9fce7">
        <Box
          direction={isSmallScreen ? 'column' : 'row'}
          justify="center"
          align={isSmallScreen ? 'center' : 'start'}
          pad={{ horizontal: isSmallScreen ? 'xsmall' : 'medium' }}
          gap="medium"
          wrap={!isSmallScreen}
        >
          {(!isSubscriptionActive || showFreemium) && (
            <PlanCard
              key="freemium"
              planPrice="FREE"
              color="#e7edfa"
              buttonColor="#e7edfa"
              benefitText="Access a single zoolife habitat"
              disabled={!!productId}
              buttonLabel={!isSubscriptionActive ? 'Current' : 'Select'}
              onClickHandler={() => loadPage('/signup')}
            />
          )}
          {isSubscriptionActive && showGiftUserPlan && userPlanData?.type === 'giftCard' && (
            <PlanCard
              key="gift-card"
              planType="Gift"
              planPrice="Free"
              planTitle="zoolife"
              planSubtitle={userPlanData.description}
              color="#e7edfa"
              buttonColor="#e7edfa"
              benefitTitle="Unlimited Access"
              disabled
              benefitText={userPlanData.daysLeft > 0 ? `${userPlanData.daysLeft} days left` : 'Expired'}
              buttonLabel="Current"
              currency="usd"
              onClickHandler={() => route('/gift')}
            />
          )}
          {isSubscriptionActive && showGiftUserPlan && userPlanData?.type === 'bundleUser' && (
            <PlanCard
              key="bundleUser"
              planType="bundleUser"
              planPrice="Free"
              planTitle="zoolife"
              planSubtitle={userPlanData.description}
              color="#e7edfa"
              buttonColor="#e7edfa"
              benefitTitle="Unlimited Access"
              disabled
              benefitText={userPlanData.daysLeft > 0 ? `${userPlanData.daysLeft} days left` : 'Expired'}
              buttonLabel="Current"
              currency="usd"
              onClickHandler={() => route('/gift')}
            />
          )}
          {plansData
            .filter(({ name, currentPlan }) => !hiddePlans.includes(name) || currentPlan)
            .map(
              ({
                name,
                price,
                interval,
                color,
                benefitTitle,
                benefitText,
                planProductId,
                priceId,
                currency,
                discount,
                currentPlan,
                label,
                clickHandler,
                disabled,
                originalPrice,
              }) => (
                <PlanCard
                  key={planProductId}
                  planName={name}
                  planPrice={price}
                  planType={interval}
                  planId={planProductId}
                  priceId={priceId}
                  planCurrency={currency}
                  color={name === 'Annual Pass' ? '#f4b98a' : '#e7edfa'}
                  buttonColor={name === 'Annual Pass' ? '#f4b98a' : '#e7edfa'}
                  benefitTitle={benefitTitle}
                  benefitText={benefitText}
                  discount={discount}
                  currentPlan={currentPlan}
                  buttonLabel={label}
                  currency={currency}
                  currencySymbol={getCurrencySymbol(currency)}
                  onClickHandler={() => {
                    if (isPublicPage) {
                      goToSignup(planProductId, priceId);
                    } else {
                      clickHandler();
                    }
                  }}
                  disabled={disabled}
                  originalPrice={originalPrice}
                  showDetailsModal={name === 'Class Pass'}
                  openDetailsModalHandler={openDetailsModalHandler}
                />
              ),
            )}
        </Box>
      </Box>
      <UpdateSubscriptionDialog
        show={dialogSettings.show}
        planId={dialogSettings.planId}
        priceId={dialogSettings.priceId}
        action={dialogSettings.action}
        interval={dialogSettings.interval}
        onCancelHandler={onCancelHandler}
      />
      {showCancelDialog && (
        <Dialog
          title="Until next time."
          text="You will still be able to access zoolife until your pass runs outs."
          buttonLabel="Back to zoolife"
          onCancel={handleCancelDialogClose}
          onConfirm={handleCancelDialogClose}
        />
      )}
      {loading && <LoaderModal background="transparent" />}
      {showCancelModal && <CancelSubscriptionModal onClose={() => setShowCancelModal(false)} />}
      {showCancelReasonModal && !showCancelModal && (
        <CancelReasonsModal
          onClose={() => setShowCancelReasonModal(false)}
          onSubmit={() => {
            setShowCancelModal(true);
            setShowCancelReasonModal(false);
          }}
        />
      )}
    </div>
  );
};

export default memo(SubscriptionManager);
