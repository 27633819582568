import { InfiniteData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { produce, WritableDraft } from 'immer';
import { merge } from 'lodash-es';
import { RefObject } from 'preact';
import { useContext, useEffect, useRef } from 'preact/hooks';

import { getAnnouncementsQueryOptions } from '@/queries/announcements';
import {
  getNotificationsInfiniteQueryOptions,
  getUnreadNotificationCountQueryOption,
  readAllNotificationsMutationFn,
  readNotificationMutationFn,
} from '@/queries/notifications';
import { BellNotification } from '@/queries/notifications/types';
import { CursorPagination } from '@/queries/types';
import { atom, useAtom } from '@/shared/atom';
import { GlobalsContext } from '@/shared/context';

export const useUnreadBellNotificationCount = () => useQuery(getUnreadNotificationCountQueryOption());

export const useReadAllNotifications = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: readAllNotificationsMutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getAnnouncementsQueryOptions().queryKey,
      });
      queryClient.invalidateQueries({
        queryKey: getNotificationsInfiniteQueryOptions().queryKey,
      });
    },
  });
};

export const useReadNotificationMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: readNotificationMutationFn,
    onSuccess: (_, notificationId) => {
      queryClient.invalidateQueries({ queryKey: getUnreadNotificationCountQueryOption().queryKey });
      queryClient.setQueryData(
        getNotificationsInfiniteQueryOptions().queryKey,
        produce((draft: WritableDraft<InfiniteData<CursorPagination<BellNotification>, unknown>> | undefined) => {
          if (!draft) return draft;

          for (let i = 0; i < draft.pages.length; i += 1) {
            const page = draft.pages[i];
            const notification = page.list.find((n) => n._id === notificationId);
            if (notification) {
              notification.isViewed = true;
              break;
            }
          }

          return draft;
        }),
      );
    },
  });
};

const isBellRungAtom = atom(false);

export const useShouldBellRing = (unread: number, ref: RefObject<HTMLDivElement>) => {
  const [isBellRung, setIsBellRung] = useAtom(isBellRungAtom);
  const previousUnread = useRef<number>();

  useEffect(() => {
    const { current: element } = ref;
    if (!element) {
      return undefined;
    }
    const handler = () => {
      setIsBellRung(true);
    };
    element.addEventListener('animationend', handler);
    return () => {
      element.removeEventListener('animationend', handler);
    };
  }, [ref, setIsBellRung]);

  useEffect(() => {
    if (previousUnread.current != null && unread > previousUnread.current) {
      setIsBellRung(false);
    }
    previousUnread.current = unread;
  }, [unread, setIsBellRung]);

  return !isBellRung;
};

export const useSubscribeNotificationsHandler = () => {
  const { userSocket } = useContext(GlobalsContext);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!userSocket) {
      return undefined;
    }
    const socketHandler = ({ unread, data }: { unread: number; data: BellNotification[] }) => {
      queryClient.setQueryData(getUnreadNotificationCountQueryOption().queryKey, { unread });
      queryClient.setQueryData(
        getNotificationsInfiniteQueryOptions().queryKey,
        produce((draft: WritableDraft<InfiniteData<CursorPagination<BellNotification>, unknown>> | undefined) => {
          if (!draft) return draft;

          const existing = draft.pages
            .flatMap((page) => page.list)
            .reduce((acc, item) => acc.set(item._id, item), new Map<string, BellNotification>());

          for (let i = 0; i < data.length; i += 1) {
            const notification = data[i];
            const current = existing.get(notification._id);
            if (current) {
              merge(current, notification);
            } else {
              draft.pages[0].list.unshift(notification);
            }
          }

          return draft;
        }),
      );
    };

    userSocket.on('receiveNotifications', socketHandler);
    return () => {
      userSocket.off('receiveNotifications', socketHandler);
    };
  }, [queryClient, userSocket]);
};
