import EmptyNotificationSVG from './empty-notifications.svg';

const EmptyNotifications = () => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center px-2 py-4">
      <img src={EmptyNotificationSVG} alt="no notifications" />
      <h3 className="preflight preflight-h3 mb-6 mt-8 font-semibold">No notifications yet</h3>
      <p className="preflight preflight-p text-sm font-light">Notification will appear here soon, hang tight!</p>
    </div>
  );
};

export default EmptyNotifications;
