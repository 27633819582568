import { useSyncExternalStore } from 'preact/compat';
import { useEffect } from 'preact/hooks';

let disallowedAutoReloadComponentsCount = 0;

const listeners = new Set<() => void>();

const subscribe = (listener: () => void) => {
  listeners.add(listener);
  return () => {
    listeners.delete(listener);
  };
};

const notifyListeners = () => {
  listeners.forEach((listener) => listener());
};

export const getDisallowedAutoReloadComponentsCount = () => disallowedAutoReloadComponentsCount;

export const useDisallowAutoReloadEffect = () => {
  useEffect(() => {
    disallowedAutoReloadComponentsCount += 1;
    notifyListeners();
    return () => {
      disallowedAutoReloadComponentsCount -= 1;
      notifyListeners();
    };
  }, []);
};

export const useDisallowedAutoReloadComponentsCount = () =>
  useSyncExternalStore(subscribe, getDisallowedAutoReloadComponentsCount);
