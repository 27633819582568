import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createSelector } from '@reduxjs/toolkit';
import { FunctionalComponent } from 'preact';
import { useMemo } from 'preact/hooks';
import { useRouter } from 'preact-router';

import { AppState, useSelector } from '@/redux/helper';

import Menu from './Menu';
import Search from './Search';
import style from './style.scss';

interface HeaderProps {
  title: string;
}

const createMatchedHabitatSelector = () =>
  createSelector(
    (state: AppState) => state.allHabitats,
    (_: AppState, slug: string | undefined) => slug,
    (allHabitats, slug) => (slug ? allHabitats.find((item) => item.slug === slug) : undefined),
  );

const Header: FunctionalComponent<HeaderProps> = ({ title }) => {
  const [currentRoute] = useRouter();
  const selectMatchedHabitat = useMemo(createMatchedHabitatSelector, []);
  const habitat = useSelector((store) => selectMatchedHabitat(store, currentRoute?.matches?.habitatSlug));

  return (
    <header className={style.mobileHeader}>
      {habitat ? (
        <>
          {currentRoute.previous ? (
            <button
              type="button"
              className="preflight preflight-button flex items-center px-5 text-[22px] text-white"
              onClick={() => window.history.back()}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
          ) : (
            <a href="/habitats" className="preflight preflight-a flex items-center px-5 text-[22px] text-white">
              <FontAwesomeIcon icon={faChevronLeft} />
            </a>
          )}

          <h1>
            <p>{habitat.animal}</p>
            <span>{habitat?.zoo?.name}</span>
          </h1>
        </>
      ) : (
        <>
          <Menu />
          <h1>{title}</h1>
          <Search />
        </>
      )}
    </header>
  );
};

export default Header;
