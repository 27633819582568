import { route as baseRoute } from 'preact-router';

const route = (path: string, replace = false, force = false) => {
  const url = new URL(path.startsWith('http') ? path : window.location.origin + path);

  if (url.origin !== window.location.origin) {
    window.location.href = url.href;
    return;
  }

  const relativeURL = url.href.replace(window.location.origin, '');

  if (!force) {
    baseRoute(relativeURL, replace);
    return;
  }

  // if we were on same page as the action URL
  // components will not re-render if URL parameters is unchanged.
  // changing route to '/' then to action URL load may be faster than whole page reload
  if (window.location.pathname === url.pathname) {
    baseRoute('/', replace);
    requestAnimationFrame(() => baseRoute(relativeURL, true));
  } else {
    baseRoute(relativeURL, replace);
  }
};

export default route;
