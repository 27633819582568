/* eslint-disable no-continue */
import { createSelector } from '@reduxjs/toolkit';
import { memoize } from 'lodash-es';
import { hasPermission, Permission, Role } from 'zl-shared/dist/auth';

import rules from '@/components/Authorize/rules';
import { defaultSorterTypes, HabitatSortType, sortHabitats } from '@/helpers/habitatsSorter';
import { HabitatListItem } from '@/queries/habitats/types';

import { AppState } from '../helper';

export const selectIsNonFreeHabitat = memoize((habitatId: string | undefined) =>
  habitatId
    ? createSelector(
        (state: AppState) => state.user.logged,
        (state: AppState) => state.user.role,
        (state: AppState) => state.user.subscription.active,
        (state: AppState) => state.user.subscription.freeHabitats,
        (logged, role, active, freeHabitats) => {
          if (!logged) return true;
          if (!active && !hasPermission(Permission.Map.AllHabitats, null, role as Role, rules)) {
            return !freeHabitats?.includes(habitatId);
          }
          return false;
        },
      )
    : () => false,
);

export const createSortedHabitatsSelector = (
  sorterTypes: readonly HabitatSortType<HabitatListItem>[] = defaultSorterTypes,
) =>
  createSelector(
    (state: AppState) => state.allHabitats,
    (state: AppState) =>
      sorterTypes.includes('freeHabitats') && ['vip', 'user'].includes(state.user.role as string)
        ? state.user.subscription
        : null,
    (state: AppState) => state.remoteConfig.lastMediaCaptureThresholdForFeaturedHabitatMinutes,
    (allHabitats, subscription, threshold) => sortHabitats(allHabitats, subscription, sorterTypes, threshold),
  );
