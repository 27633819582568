import { PrimaryButton } from 'Components/Buttons';
import CloseButton from 'Components/modals/CloseButton';
import { Layer, RadioButtonGroup } from 'grommet';
import { useCallback, useEffect, useState } from 'preact/hooks';
import { buildURL } from 'Shared/fetch';
import useFetch from 'use-http';

import { useSelector } from '@/redux/helper';

import CancelationFlowOffer from '../CancelationFlowOffer';
import style from './style.scss';

const originalReasons = [
  {
    step: 1,
    question: 'Thinking of cancelling? Happy to help. What’s the reason if we might ask?',
    options: [
      { text: "Can't afford it right now", nextStep: 2 },
      { text: 'Not really using it right now', nextStep: 3 },
      { text: 'Having a technical issue', nextStep: 4 },
    ],
  },
  {
    step: 2,
    question: 'Could you tell us more about not being able to afford it right now?',
    options: [
      { text: 'Trying to cut costs', offer: 'pauseSubscription' },
      { text: 'I would like more animals / content / hosted events', offer: 'percentageOff' },
      { text: 'I would like improved features and functionality', offer: 'percentageOff' },
    ],
  },
  {
    step: 3,
    question: "Could you tell us the biggest reason you're not using zoolife?",
    options: [
      { text: 'Maybe if there was more content to explore', offer: 'percentageOff' },
      { text: 'My school / teaching / research project is now complete', offer: 'pauseSubscription' },
      { text: 'Maybe if it was more convenient to use', offer: 'percentageOff' },
      { text: 'Nothing would make me use zoolife more' }, // no offer for this one
      { text: 'Maybe if I had more time or wasn’t so busy', offer: 'percentageOff' },
    ],
  },
  {
    step: 4,
    question: 'Could you tell us more about what we can fix for you?',
    options: [
      { text: 'Not enough content to explore', offer: 'percentageOff' },
      { text: 'Loading issues with the video feeds', offer: 'oneMonthOnUs' },
      { text: 'Other', offer: 'oneMonthOnUs' },
    ],
  },
];

const CancelReasonsModal = ({ onClose, onSubmit }) => {
  const [reasons, setReasons] = useState(() => {
    return originalReasons;
  });
  const [currentStep, setCurrentStep] = useState(1);
  const [reason, setReason] = useState({});
  const [text, setText] = useState('');
  const [showOffer, setShowOffer] = useState(false);

  const subscriptionStatus = useSelector((state) => state.user.subscription);
  // first we check the localstorage, if user has used the one of the offers, we don't call the API for eligibleoffers
  const checkLocalStorageEligibility = () => {
    try {
      const usedOffers = JSON.parse(localStorage.getItem('cancelationFlowUsedOffers') || '[]');
      const isEligible = !usedOffers.some((offer) => offer.name === 'oneMonthOnUs' || offer.name === 'percentageOff');
      return isEligible;
    } catch (error) {
      console.error('Error reading from localStorage:', error);
      return true; // proceed with API call to check the eligibile offers if there is an error
    }
  };

  const updateReasonsWithEligibleOffers = (eligibleOffers) => {
    const updatedReasons = originalReasons.map((step) => ({
      ...step,
      options: step.options.map((option) => {
        if (option.offer) {
          if (eligibleOffers.includes(option.offer)) {
            return option;
          }
          return { ...option, offer: null };
        }
        return option;
      }),
    }));
    setReasons(updatedReasons);
  };

  const { get, response } = useFetch(buildURL('/checkout/checkEligibleCancelationFlowOffers'), {
    cachePolicy: 'no-cache',
  });

  useEffect(() => {
    const fetchEligibleOffers = async () => {
      const isEligibleLocally = checkLocalStorageEligibility();

      if (!isEligibleLocally) {
        const eligibleOffers = [];
        if (!subscriptionStatus.resumeDate) {
          eligibleOffers.push('pauseSubscription');
        }
        updateReasonsWithEligibleOffers(eligibleOffers);
      } else {
        try {
          const result = await get();
          if (response.ok) {
            updateReasonsWithEligibleOffers(result.eligibleOffers);
          } else {
            updateReasonsWithEligibleOffers([]);
          }
        } catch (error) {
          console.error('Error fetching eligible offers:', error);
          updateReasonsWithEligibleOffers([]);
        }
      }
    };

    fetchEligibleOffers();
  }, []);

  const onChangeHandler = useCallback(
    (event) => {
      const selected = reasons
        .find(({ step }) => step === currentStep)
        .options.find(({ text }) => text === event.target.value);
      setReason(selected);

      setTimeout(() => {
        document.getElementById('cancelText')?.focus();
        document.getElementById('cancelButton').scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 200);
    },
    [currentStep],
  );

  const { post, data, error, loading } = useFetch(buildURL('/users/subscription/cancelReason'), {
    credentials: 'include',
    cachePolicy: 'no-cache',
  });

  useEffect(() => {
    if (error) {
      console.error('Error while sending cancellation reason:', error);
      onSubmit();
    }
  }, [error, onClose, onSubmit]);

  useEffect(() => {
    if (data && !reason.offer) {
      onSubmit();
    }
  }, [data, onSubmit]);

  const submitHandler = () => {
    post({ reason: `${reason.text}: ${text}` });
  };

  const currentReason = reasons.find(({ step }) => step === currentStep);

  const handleNext = () => {
    if (reason.offer) {
      submitHandler();
      setShowOffer(true);
    } else if (reason.nextStep) {
      setCurrentStep(reason.nextStep);
    } else {
      submitHandler();
    }
  };

  const handleBack = () => {
    const previousStep = reasons.find(({ options }) => options.some(({ nextStep }) => nextStep === currentStep));
    if (previousStep) {
      setCurrentStep(previousStep.step);
    }
  };

  const shouldShowTextArea =
    currentStep > 1 && reason.text && currentReason.options.some((option) => option.text === reason.text);

  return (
    <Layer onClickOutside={onClose} onEsc={onClose} className={style.cancelReasonContainer}>
      <CloseButton varient="grey" onClick={onClose} className={style.close} />
      {showOffer && reason.offer ? (
        <CancelationFlowOffer offerType={reason.offer} onNoThanks={onSubmit} onAfterClaimOfferClose={onClose} />
      ) : (
        <>
          <h4 className="m-6 mb-1 mt-8 text-xl font-bold leading-8">{currentReason.question}</h4>
          <div className={style.container}>
            <div>
              <RadioButtonGroup
                name="reasons"
                className={style.radioButtons}
                options={currentReason.options.map(({ text }) => text)}
                value={reason.text}
                onChange={onChangeHandler}
              />

              {shouldShowTextArea && (
                <textarea
                  id="cancelText"
                  cols="30"
                  rows="10"
                  maxLength="1500"
                  placeholder="Tell us more…"
                  value={text}
                  onChange={(event) => setText(event.target.value)}
                />
              )}

              <div className={style.buttonsContainer} id="cancelButton">
                <div className={style.backButton} onClick={handleBack}>
                  {currentStep > 1 && (
                    <>
                      <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
                        <path
                          d="M0.205078 5.51313C-0.0683594 5.78169 -0.0683594 6.21782 0.205078 6.48638L4.40508C4.67852 10.8799 5.12258 10.8799C5.39602 10.61142 5.39602 9.63813L1.69039L5.39383C5.66727 5.66727 1.38599C5.12039 1.11743 4.67633 1.11743 4.40289 0.20289 0.205078 5.51313Z"
                          fill="#8C8C8C"
                        />
                      </svg>
                      Back
                    </>
                  )}
                </div>

                <PrimaryButton
                  type="button"
                  label="Submit"
                  onClick={handleNext}
                  loading={loading}
                  className={style.nextButton}
                  disabled={!!(!reason.text || (currentStep > 1 && (!text || reason.nextStep)))}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </Layer>
  );
};

export default CancelReasonsModal;
