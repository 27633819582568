import { faBlog, faCartShopping, faGift, faHome, faSignInAlt, faSignOut, faTicket } from '@fortawesome/pro-solid-svg-icons';
import { useCallback } from 'preact/hooks';

import BurgerMenu from '@/components/BurgerMenu';
import { LandingSecondary } from '@/components/Buttons';
import NavItem from '@/components/NavBar/NavItem';
import { useShouldDisableJSRouting } from '@/components/providers/DisableJSRoutingProvider';
import { getHomeURL, isEmbedded } from '@/helpers';
import { useSelector } from '@/redux/helper';
import { goToSignup } from '@/routes/home/helpers';
import { logLandingButton } from '@/shared/ga';

import TalkNavItem from '../TalkNavItem';

const PublicHeaderContent = ({ isLargeScreen, logEvents = false }: { isLargeScreen?: boolean; logEvents?: boolean }) => {
  const emphasizeGiftCard = useSelector((state) => state.remoteConfig.emphasizeGiftCard);
  const giftCardIconURL = useSelector((state) => state.remoteConfig.giftCardSpecialIconURL);
  const shouldDisableJSRouting = useShouldDisableJSRouting();
  const embedded = isEmbedded();

  const origin = window?.location.origin ?? '';
  const target = shouldDisableJSRouting ? '_parent' : embedded ? '_blank' : undefined;

  const onPressSignUp = useCallback(() => {
    goToSignup();
    logLandingButton('Header-Sign-Up');
  }, []);

  const logEvent = useCallback(
    (action: string) => {
      if (logEvents) {
        logLandingButton(action);
      }
    },
    [logEvents],
  );

  const signUpButton = (
    <LandingSecondary onClick={onPressSignUp} className="!mr-3 !h-[31px] !w-[130px] !p-0 !text-base !font-bold">
      Sign up
    </LandingSecondary>
  );

  return isLargeScreen ? (
    <>
      <NavItem
        avoidInnerPadding
        text="Home"
        url={getHomeURL()}
        icon={faHome}
        target={target}
        onClick={() => logEvent('Header-Home')}
      />
      <NavItem
        avoidInnerPadding
        text="Pricing"
        url={`${origin}/prices`}
        icon={faTicket}
        target={target}
        onClick={() => logEvent('Header-Pricing')}
      />
      <NavItem
        avoidInnerPadding
        text="Gift Cards"
        url={`${origin}/gift`}
        icon={faGift}
        target={target}
        onClick={() => logEvent('Header-Gift-Cards')}
        customIcon={
          emphasizeGiftCard ? (
            <img className="preflight preflight-img -ml-[3px] mb-1 mr-0.5 w-6" src={giftCardIconURL} alt="gift box" />
          ) : undefined
        }
      />
      <TalkNavItem avoidInnerPadding target={target} onClick={() => logEvent('Header-Schedules')} />
      <NavItem
        avoidInnerPadding
        text="Log In"
        url={`${origin}/login`}
        icon={faSignInAlt}
        target={target}
        onClick={() => logEvent('Header-Log-In')}
      />
      {signUpButton}
    </>
  ) : (
    <BurgerMenu>
      <NavItem text="Home" url={`${origin}/home`} icon={faHome} target={target} onClick={() => logEvent('Header-Home')} />
      <NavItem
        text="Pricing"
        url={`${origin}/prices`}
        icon={faTicket}
        target={target}
        onClick={() => logEvent('Header-Pricing')}
      />
      <NavItem
        text="Gift Cards"
        url={`${origin}/gift`}
        icon={faGift}
        onClick={() => logEvent('Header-Gift-Cards')}
        target={target}
        customIcon={
          emphasizeGiftCard ? (
            <img className="preflight preflight-img -ml-[3px] mb-1 mr-0.5 w-6" src={giftCardIconURL} alt="gift box" />
          ) : undefined
        }
      />
      <TalkNavItem target={target} onClick={() => logEvent('Header-Schedules')} />
      <NavItem
        text="Blog"
        url="https://zoolife.tv/blog"
        icon={faBlog}
        target="_blank"
        onClick={() => logEvent('Header-Blog')}
      />
      <NavItem
        text="Store"
        url="https://zoolifetv.bigcartel.com/"
        icon={faCartShopping}
        target="_blank"
        onClick={() => logEvent('Header-Store')}
      />
      <NavItem text="Log In" url={`${origin}/login`} icon={faSignOut} onClick={() => logEvent('Header-Log-In')} />
      <div className="ml-4">{signUpButton}</div>
    </BurgerMenu>
  );
};

export default PublicHeaderContent;
