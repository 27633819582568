import { queryOptions } from '@tanstack/react-query';

import { buildURL, post } from '@/shared/fetch';

import { getQueryFn } from '../utils';
import { AnnouncementWithViewed } from './types';

export const getAnnouncementsQueryOptions = () =>
  queryOptions({
    queryKey: ['announcements', 'list'],
    queryFn: getQueryFn<{ list: AnnouncementWithViewed[] }>('/announcements'),
    select: ({ list }) => list,
  });

export const viewAnnouncementMutationFn = (announcementId: string) => {
  return post<void>(buildURL(`/announcements/${announcementId}/viewed`));
};
