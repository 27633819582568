import { get } from 'lodash-es';

import { hasPermission } from '../components/Authorize';
import { getConfig } from '../helpers';
import {
  ADD_MESSAGES,
  ADD_USER_INTERACTION,
  BAN_CHAT_USER,
  CLEAR_MESSAGES,
  MARK_MESSAGE_AS_DELETED,
  MARK_MESSAGE_AS_PINNED,
  REMOVE_USER_INTERACTION,
  SET_CLIP_DATA,
  SET_FULL_SCREEN,
  SET_GEO_INFO,
  SET_HABITATS,
  SET_IS_MULTI_FREE_HABITAT,
  SET_REPLY_MESSAGE,
  SET_SHOW_VIV_TRANSCRIPT,
  SET_SNAPSHOT_DATA,
  SET_VIV_SUPPORTS_TRANSCRIPT,
  SHOW_SNAPSHOT_SHARE_POPUP,
  TOGGLE_EMOJI_STICKER,
  TOGGLE_IS_BROADCASTING,
  TOGGLE_IS_STREAM_PLAYING,
  TOGGLE_MESSAGE_REACTION,
  TOGGLE_SHOW_EMOJI_BASKET,
  TOGGLE_STREAM_AREA_WARNING_MSG,
  UNSET_HABITATS,
  UPDATE_HABITAT,
  UPDATE_VIEWERS,
} from './types';

let interactionId = 0;
const newUserInteraction = (payload) => ({
  type: ADD_USER_INTERACTION,
  payload: { ...payload },
});

const removeUserInteraction = (interactionId) => ({
  type: REMOVE_USER_INTERACTION,
  payload: { interactionId },
});

export const addUserInteraction = (payload) => (dispatch, getState) => {
  const configs = get(getState(), 'habitat.habitatInfo.selectedCamera.configs', []);
  const { configValue: votingTime } = getConfig(configs, 'ptu.votingTime');
  // eslint-disable-next-line no-plusplus
  const id = ++interactionId;
  const extraTime = hasPermission('camera:block') ? 3000 : 1000;

  dispatch(newUserInteraction({ ...payload, interactionId: id }));
  setTimeout(() => dispatch(removeUserInteraction(id)), payload.ttl + extraTime || Number(votingTime) + extraTime);
};

export const toggleIsStreamPlaying = () => ({ type: TOGGLE_IS_STREAM_PLAYING });
export const toggleShowEmojiBasket = () => ({ type: TOGGLE_SHOW_EMOJI_BASKET });

export const toggleEmojiSticker = ({ isOn, emojiSrc }) => ({ type: TOGGLE_EMOJI_STICKER, payload: { isOn, emojiSrc } });

export const toggleStreamWarningMsg = (payload) => ({ type: TOGGLE_STREAM_AREA_WARNING_MSG, payload });

let messageId = 0;

const addChatMessage = (channelId, messages) => ({
  type: ADD_MESSAGES,
  payload: { channelId, messages },
});

export const addMessages = (channelId, messages) => (dispatch) => {
  const messageList = messages.map((message) => {
    messageId += 1;
    return { ...message, messageId };
  });

  messageId += 1;
  dispatch(addChatMessage(channelId, messageList));
};

export const clearMessages = (channelId) => ({
  type: CLEAR_MESSAGES,
  payload: { channelId },
});

export const markMessageAsDeleted = (channelId, messageId) => ({
  type: MARK_MESSAGE_AS_DELETED,
  payload: { channelId, messageId },
});

export const markMessageAsPinned = (channelId, messageId, actionTimetoken, value) => ({
  type: MARK_MESSAGE_AS_PINNED,
  payload: { channelId, messageId, actionTimetoken, value },
});

export const toggleMessageReaction = (channelId, messageId, reaction, reactionId, userId) => ({
  type: TOGGLE_MESSAGE_REACTION,
  payload: {
    channelId,
    messageId,
    reaction,
    reactionId,
    userId,
  },
});

export const setReplyMessage = (timetoken, userId, username, animal, color, text, channel) => ({
  type: SET_REPLY_MESSAGE,
  payload: {
    timetoken,
    userId,
    username,
    text,
    animal,
    color,
    channel,
  },
});

export const clearReplyMessage = () => ({
  type: SET_REPLY_MESSAGE,
  payload: {
    timetoken: null,
    userId: null,
    username: null,
    animal: null,
    color: null,
    text: null,
  },
});

export const banChatUser = (username) => ({
  type: BAN_CHAT_USER,
  payload: { username },
});

export const showSnapshotShare = (show) => ({
  type: SHOW_SNAPSHOT_SHARE_POPUP,
  payload: { show },
});

export const toggleIsBroadcasting = () => ({ type: TOGGLE_IS_BROADCASTING });

export const setHabitats = (habitats) => ({
  type: SET_HABITATS,
  payload: { habitats },
});

export const unsetHabitats = () => ({ type: UNSET_HABITATS });

export const updateHabitat = (habitatId, data) => ({
  type: UPDATE_HABITAT,
  payload: { habitatId, data },
});

export const setHabitatViewers = (viewers) => ({
  type: UPDATE_VIEWERS,
  payload: { viewers },
});

export const setFullScreen = (isActive) => ({
  type: SET_FULL_SCREEN,
  payload: { isActive },
});

export const setSnapshotData = (data = {}) => ({
  type: SET_SNAPSHOT_DATA,
  payload: { data },
});

export const setClipData = (data = {}) => ({
  type: SET_CLIP_DATA,
  payload: { data },
});

export const setIsMultiFreeHabitat = (data = false) => ({
  type: SET_IS_MULTI_FREE_HABITAT,
  payload: { data },
});

export const setVivSupportsTranscript = (data) => ({
  type: SET_VIV_SUPPORTS_TRANSCRIPT,
  payload: data,
});

export const setShowVivTranscript = (data = false) => ({
  type: SET_SHOW_VIV_TRANSCRIPT,
  payload: data,
});

export const setGeoInfo = (data) => ({
  type: SET_GEO_INFO,
  payload: data,
});
