import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Layer } from 'grommet';

import { useIsMobileSize } from '../../../../hooks';
import style from './style.scss';

const ClassPassDetailsModal = ({ onClose }) => {
  const isMobileSize = useIsMobileSize();
  return (
    <Layer position="center" onClickOutside={onClose} onEsc={onClose} background="transparent" animation="fadeIn">
      <Box
        style={{ position: 'relative' }}
        height={{ max: '90vh' }}
        justify="center"
        align="center"
        className={style.modalWrapper}
      >
        <Button plain onClick={onClose} icon={<FontAwesomeIcon size="2x" icon={faTimes} />} className={style.closeButton} />
        <div>
          <img
            src={isMobileSize ? 'https://zoolife.tv/assets/mobile.webp' : 'https://zoolife.tv/assets/desktop.webp'}
            alt="class pass details"
          />
        </div>
      </Box>
    </Layer>
  );
};
export default ClassPassDetailsModal;
