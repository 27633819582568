import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'preact/hooks';
import { useDispatch } from 'react-redux';

import { RemoteConfigResponse } from '@/redux/slices/remoteConfig';
import { setRemoteConfig } from '@/redux/slices/remoteConfig/actions';

const RemoteConfigUpdater = () => {
  const dispatch = useDispatch();
  const { data } = useQuery<RemoteConfigResponse>({ queryKey: ['/configuration'] });

  useEffect(() => {
    if (data) dispatch(setRemoteConfig(data));
  }, [dispatch, data]);

  return null;
};

export default RemoteConfigUpdater;
