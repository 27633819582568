import { FunctionComponent, h } from 'preact';
import { memo } from 'preact/compat';
import { useEffect, useMemo } from 'preact/hooks';
import { useDispatch } from 'react-redux';
import { GlobalsContext } from 'Shared/context';
import io from 'socket.io-client';

import getDeviceId from '@/helpers/getDeviceId';
import { useSelector } from '@/redux/helper';
import { setUserData } from '@/redux/slices/user/actions';

const GlobalsContextProvider: FunctionComponent = ({ children }) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user.userId);
  const deviceId = getDeviceId();

  const socket = useMemo(() => {
    if (!deviceId) {
      return undefined;
    }

    return io(`${process.env.PREACT_APP_WS_PROTOCOL}${process.env.PREACT_APP_API_AUTHORITY}`, {
      transports: ['websocket'],
      auth: { deviceId, userId },
      autoConnect: false,
    });
  }, [deviceId, userId]);

  const userSocket = useMemo(() => {
    if (!userId || !deviceId) {
      return undefined;
    }

    return io(`${process.env.PREACT_APP_WS_PROTOCOL}${process.env.PREACT_APP_API_AUTHORITY}/${userId}`, {
      transports: ['websocket'],
      auth: { deviceId, userId },
      autoConnect: false,
    });
  }, [deviceId, userId]);

  useEffect(() => {
    if (!socket) {
      return undefined;
    }
    socket.once('connect', () => console.log('socket connected'));
    socket.connect();
    return () => {
      socket.disconnect();
      socket.close();
    };
  }, [socket]);

  useEffect(() => {
    if (!userSocket) {
      return undefined;
    }
    userSocket.once('connect', () => console.log('userSocket connected'));
    userSocket.connect();
    // FIXME: Dunno why this is needed, but sometimes, the socket cannot be connected
    // and it didn't try to reconnect even the reconnect option is set to true
    let unmounted = false;
    const id = setTimeout(() => {
      if (!unmounted && !userSocket.connected) {
        userSocket.connect();
      }
    }, 1000);
    return () => {
      unmounted = true;
      clearTimeout(id);
      userSocket.disconnect();
      userSocket.close();
    };
  }, [userSocket]);

  useEffect(() => {
    if (!userSocket) {
      return undefined;
    }

    const disableUser = ({ disabledAt }: { disabledAt: string }) => {
      // this flag will be sent on signup to stop disabled users from creating multiple accounts
      // it will also be stored on login if user is disabled
      localStorage.setItem('userDisabledAt', disabledAt);
      window.location.reload();
    };

    const banUser = ({ user }: { user: Parameters<typeof setUserData>[0] }) => {
      dispatch(setUserData(user));
    };

    userSocket.on('disableUser', disableUser);
    userSocket.on('banUserUntil', banUser);
    return () => {
      userSocket.off('disableUser', disableUser);
      userSocket.off('banUserUntil', banUser);
    };
  }, [userSocket, dispatch]);

  return <GlobalsContext.Provider value={{ socket, userSocket }}>{children}</GlobalsContext.Provider>;
};

export default memo(GlobalsContextProvider);
