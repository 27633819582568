import { useEffect } from 'preact/hooks';
import { buildURL } from 'Shared/fetch';
import { useFetch } from 'use-http';

import { setGeoInfo } from '../../../redux/actions';
import store from '../../../redux/store';

interface CurrencyInfo {
  name: null | string;
  symbol: null | string;
  isUsOrCa: boolean;
}

export const getCurrencySymbol = (currency: string | null) => {
  const symbols: { [key: string]: string } = {
    usd: '$',
    eur: '€',
    gbp: '£',
    aud: 'AU$',
    cad: 'CA$',
    nzd: '$NZ',
  };

  return currency ? symbols[currency] : '$';
};

export const useCurrency = (name?: null | string) => {
  const currencyInfo: CurrencyInfo = {
    name: null,
    symbol: null,
    isUsOrCa: false,
  };
  const { geoInfo: { isUsOrCa: isUsOrCaState, currency: currencyState } = {} } = store.getState();
  const { get, data: { currency, isUsOrCa } = {}, loading } = useFetch(buildURL('/checkout/currency'));

  useEffect(() => {
    if (!name && !currencyState) {
      if (currency) {
        store.dispatch(setGeoInfo({ isUsOrCa, currency }));
      } else {
        get();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency]);
  if (!name && !currencyState) {
    currencyInfo.name = currency;
    currencyInfo.isUsOrCa = isUsOrCa;
  } else if (name) {
    currencyInfo.name = name;
  } else {
    currencyInfo.name = currencyState;
    currencyInfo.isUsOrCa = isUsOrCaState;
  }

  currencyInfo.symbol = getCurrencySymbol(currencyInfo.name);

  return { ...currencyInfo, loading: loading ?? false };
};
