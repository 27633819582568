/* eslint-disable no-continue */
import { differenceInMinutes } from 'date-fns';

import { HabitatListItem } from '@/queries/habitats/types';

type Habitat = Pick<
  HabitatListItem,
  '_id' | 'title' | 'isStreamOn' | 'liveTalk' | 'index' | 'currentViewers' | 'lastMediaCapture'
>;

export type HabitatSortType<T extends Habitat = Habitat> =
  | 'lastMediaCapture'
  | 'liveTalk'
  | 'freeHabitats'
  | 'online'
  | 'index'
  | 'currentViewers'
  | 'title'
  | 'id'
  | ((a: T, b: T) => number);

export const defaultSorterTypes = Object.freeze([
  'liveTalk',
  'freeHabitats',
  'online',
  'index',
  'currentViewers',
  'title',
] as HabitatSortType[]);

export const sortHabitats = <T extends Habitat>(
  habitats: T[],
  subscription: { active?: boolean | null; freeHabitats?: string[] } | null | undefined,
  sorterTypes: readonly HabitatSortType<T>[] = defaultSorterTypes,
  lastMediaCaptureThreshold: number,
) => {
  const { active, freeHabitats } = subscription ?? {};
  const freeHabitatSet = new Set(freeHabitats ?? []);

  const isMediaCaptureRecent = (timestamp: string) => {
    const minutesAgo = differenceInMinutes(Date.now(), Date.parse(timestamp));
    return minutesAgo <= lastMediaCaptureThreshold;
  };

  const getMediaCaptureValue = (timestamp: string | undefined) => {
    if (!timestamp || !isMediaCaptureRecent(timestamp)) {
      return null;
    }
    return Date.parse(timestamp);
  };

  return [...habitats].sort((a, b) => {
    for (let i = 0; i < sorterTypes.length; i += 1) {
      const sorterType = sorterTypes[i];
      if (sorterType instanceof Function) {
        const sorterResult = sorterType(a, b);
        if (sorterResult === 0) {
          continue;
        }
        return sorterResult;
      }
      let aValue: number | null;
      let bValue: number | null;

      switch (sorterType) {
        case 'lastMediaCapture':
          aValue = getMediaCaptureValue(a.lastMediaCapture);
          bValue = getMediaCaptureValue(b.lastMediaCapture);

          if (!aValue && !bValue) {
            continue;
          }

          if (!aValue || !bValue) {
            return !aValue ? 1 : -1;
          }

          return bValue - aValue;

        case 'liveTalk':
          if (a.liveTalk !== b.liveTalk) {
            return b.liveTalk ? 1 : -1;
          }
          continue;

        case 'freeHabitats':
          if (!active && freeHabitats) {
            const aIsFree = freeHabitatSet.has(a._id);
            const bIsFree = freeHabitatSet.has(b._id);
            if (aIsFree !== bIsFree) {
              return aIsFree ? -1 : 1;
            }
          }
          continue;

        case 'online':
          if (a.isStreamOn !== b.isStreamOn) {
            return b.isStreamOn ? 1 : -1;
          }
          continue;

        case 'index':
          if ((a.index || Number.MAX_SAFE_INTEGER) !== (b.index || Number.MAX_SAFE_INTEGER)) {
            return (a.index || Number.MAX_SAFE_INTEGER) - (b.index || Number.MAX_SAFE_INTEGER);
          }
          continue;

        case 'currentViewers':
          if (a.currentViewers !== b.currentViewers) {
            return b.currentViewers - a.currentViewers;
          }
          continue;

        case 'id':
          // `id` should not be equal, so we can safely return the result
          return a._id.localeCompare(b._id);

        case 'title':
        default:
          // `title` should not be equal, so we can safely return the result
          return a.title.localeCompare(b.title);
      }
    }
    // No sorting if no sorter types are left
    return 0;
  });
};
