import { useQuery } from '@tanstack/react-query';
import { memo } from 'preact/compat';
import { useContext, useEffect } from 'preact/hooks';
import { useDispatch } from 'react-redux';

import { getAllHabitatsQueryOptions, getHabitatQueryOptions } from '@/queries/habitats';
import { setHabitats } from '@/redux/actions';
import { useSelector } from '@/redux/helper';
import { setHabitat } from '@/routes/habitat/actions';
import { GlobalsContext } from '@/shared/context';

const HabitatsUpdater = () => {
  const { socket } = useContext(GlobalsContext);
  const dispatch = useDispatch();

  const zooSlug = useSelector((state) => state.habitat.habitatInfo?.zoo?.slug);
  const slug = useSelector((state) => state.habitat.habitatInfo?.slug);

  const { data: allHabitats } = useQuery({
    ...getAllHabitatsQueryOptions(),
    refetchInterval: 60 * 1000,
  });

  const { data: habitat, refetch } = useQuery(getHabitatQueryOptions(slug, zooSlug));

  useEffect(() => {
    if (zooSlug && slug && socket) {
      socket?.on('habitatUpdated', () => refetch());
      return () => {
        socket?.off('habitatUpdated');
      };
    }
    return null;
  }, [socket, refetch, zooSlug, slug, dispatch]);

  useEffect(() => {
    if (allHabitats) {
      dispatch(setHabitats(allHabitats));
    }
  }, [allHabitats, dispatch]);

  useEffect(() => {
    if (habitat) {
      dispatch(setHabitat({ habitat }));
    }
  }, [habitat, dispatch]);

  return null;
};

export default memo(HabitatsUpdater);
