/* eslint-disable react/jsx-props-no-spreading */

import { HTMLAttributes } from 'preact/compat';
import { useDispatch } from 'react-redux';

import { setMembersOnlyWarningModalState } from '@/components/MembersOnlyWarningModal/redux/actions';
import { useSelector } from '@/redux/helper';
import { selectIsNonFreeHabitat } from '@/redux/selectors/habitats';

import { buildHabitatURL } from './utils';

export interface HabitatLinkProps extends Omit<HTMLAttributes<HTMLAnchorElement>, 'href'> {
  habitatId: string;
  slug: string;
  zooSlug: string;
  query?: string;
}

type AccessibleHabitatLinkProps = Omit<HabitatLinkProps, 'habitatId' | 'disableMembersOnlyWarning'>;

const AccessibleHabitatLink = ({ slug, zooSlug, query, children, ...rest }: AccessibleHabitatLinkProps) => (
  <a href={buildHabitatURL(zooSlug, slug, query)} {...rest}>
    {children}
  </a>
);

const RestrictedHabitatLink = ({ habitatId, slug, zooSlug, children, onClick, ...rest }: HabitatLinkProps) => {
  const dispatch = useDispatch();
  return (
    <AccessibleHabitatLink
      slug={slug}
      zooSlug={zooSlug}
      {...rest}
      data-native
      onClick={(e) => {
        e.preventDefault();
        dispatch(
          setMembersOnlyWarningModalState({
            habitat: {
              id: habitatId,
              slug,
              zooSlug,
            },
            type: window.location.pathname.includes('schedule') ? 'event' : null,
          }),
        );
        onClick?.(e);
      }}
    >
      {children}
    </AccessibleHabitatLink>
  );
};

const HabitatLink = ({ habitatId, children, ...rest }: HabitatLinkProps) => {
  const isNonFreeHabitat = useSelector(selectIsNonFreeHabitat(habitatId));
  return isNonFreeHabitat ? (
    <RestrictedHabitatLink habitatId={habitatId} {...rest}>
      {children}
    </RestrictedHabitatLink>
  ) : (
    <AccessibleHabitatLink {...rest}>{children}</AccessibleHabitatLink>
  );
};

export default HabitatLink;
