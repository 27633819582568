import { useEffect, useState } from 'preact/hooks';

import { useWindowResize } from '@/hooks';
import { useSelector } from '@/redux/helper';

import { useFetchUserIfNeeded } from '../Authorize/hooks';
import ZoolifeLogo from '../ZoolifeLogo';
import AuthenticatedHeaderContent from './Content/AuthenticatedHeaderContent';
import PublicHeaderContent from './Content/PublicHeaderContent';
import Search from './Search';

interface HeaderProps {
  /** Used for pages that didn't wrapped in `AuthGuard`, currently no use */
  fetchUser?: boolean;
  /** Only work for non-logged in user */
  logEvents?: boolean;
}

const cutOffWidth = 1192;

const FetchUser = () => {
  useFetchUserIfNeeded();
  return null;
};

const Header = ({ fetchUser, logEvents }: HeaderProps) => {
  const logged = useSelector((state) => state.user.logged);
  const { width } = useWindowResize();
  const containableSearchBar = width > 440;
  const [searchShow, setSearchShow] = useState(containableSearchBar);

  useEffect(() => {
    setSearchShow(containableSearchBar);
  }, [containableSearchBar]);

  useEffect(() => {
    if (!logged) {
      setSearchShow(false);
    }
  }, [logged]);

  const largeScreen = width > cutOffWidth;

  return (
    <header className="fixed inset-x-0 top-0 z-[19] flex h-[var(--headerHeight)] items-center justify-between bg-white p-0 shadow-[0_0_5px_rgba(41_31_31_/.5)] fullscreen:hidden">
      <div className="flex flex-grow items-center">
        <div className="px-4">{(containableSearchBar || !searchShow) && <ZoolifeLogo />}</div>
        {logged && (
          <Search
            className="w-[min(calc(100%),500px)] !px-7 max-xs:w-full"
            switchableSearch={largeScreen ? false : !containableSearchBar}
            searchShow={largeScreen ? true : searchShow}
            setSearchShow={largeScreen ? undefined : setSearchShow}
            iconsPosition="left"
          />
        )}
      </div>
      <div className="ml-2 flex items-center gap-5">
        {logged ? (
          <AuthenticatedHeaderContent isLargeScreen={largeScreen} />
        ) : (
          <PublicHeaderContent isLargeScreen={largeScreen} logEvents={logEvents} />
        )}
      </div>
      {fetchUser && <FetchUser />}
    </header>
  );
};

export default Header;
