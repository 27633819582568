import {
  faAnalytics,
  faBlog,
  faBullhorn,
  faCartShopping,
  faCctv,
  faComment,
  faCreditCard,
  faDownload,
  faGift,
  faGlobeAmericas,
  faHeart,
  faHouse,
  faIdCard,
  faIdCardClip,
  faImage,
  faList,
  faQuestionCircle,
  faSignOut,
  faTools,
  faUsersCog,
} from '@fortawesome/pro-solid-svg-icons';
import { HTMLAttributes } from 'preact/compat';
import { useCallback } from 'preact/hooks';
import { useDispatch } from 'react-redux';

import { hasPermission, isFreeUser } from '@/components/Authorize';
import BellNotifications from '@/components/BellNotifications';
import BurgerMenu from '@/components/BurgerMenu';
import { PrimaryButton } from '@/components/Buttons';
import TalkNavItem from '@/components/Header/TalkNavItem';
import NavItem from '@/components/NavBar/NavItem';
import { useShouldDisableJSRouting } from '@/components/providers/DisableJSRoutingProvider';
import { loadPage } from '@/helpers';
import { useIsMobileSize } from '@/hooks';
import { useSelector } from '@/redux/helper';
import { unsetUserData } from '@/redux/slices/user/actions';
import { partnerMPLinkFetcher } from '@/shared/fetch';
import { trackMP } from '@/shared/mp';

import { useLogout } from '../../Authorize/hooks';
import { openInstallAppModal } from '../../modals/InstallApp/actions';
import { openSurveyModal } from '../../modals/Survey/actions';
import { addNotification } from '../../Notifications/actions';
import UserMenu from '../Menu';

const MenuHeader = ({ children, ...props }: HTMLAttributes<HTMLHeadingElement>) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <h4 className="pointer-events-none mb-1 ml-4 mt-2.5 cursor-default" {...props}>
    {children}
  </h4>
);

const Divider = () => <hr className="h-0.5 w-[calc(100%-16px)] bg-black" />;

const AuthenticatedHeaderContent = ({ isLargeScreen }: { isLargeScreen: boolean }) => {
  const dispatch = useDispatch();
  const emphasizeGiftCard = useSelector((state) => state.remoteConfig.emphasizeGiftCard);
  const giftCardIconURL = useSelector((state) => state.remoteConfig.giftCardSpecialIconURL);
  const username = useSelector((state) => state.user.username);
  const habitatId = useSelector((state) => state.habitat?._id);
  const isMobileSize = useIsMobileSize();
  const shouldDisableJSRouting = useShouldDisableJSRouting();

  const logout = useLogout(useCallback(() => dispatch(unsetUserData()), [dispatch]));

  return isLargeScreen ? (
    <>
      <nav>
        <ul className="preflight preflight-ul flex select-none items-center gap-6">
          <li>
            <NavItem avoidInnerPadding text="Home" url="/home" icon={faHouse} />
          </li>
          <li>
            <NavItem avoidInnerPadding text="My Animals" url="/habitats?tab=favorites" icon={faHeart} />
          </li>
          {emphasizeGiftCard && (
            <li>
              <NavItem
                avoidInnerPadding
                text="Gift Cards"
                url="/gift"
                customIcon={
                  <img className="preflight preflight-img -ml-[3px] mb-1 mr-0.5 w-6" src={giftCardIconURL} alt="gift box" />
                }
              />
            </li>
          )}
          <li>
            <TalkNavItem avoidInnerPadding />
          </li>
        </ul>
      </nav>
      {isFreeUser() && (
        <div>
          <a
            data-native={shouldDisableJSRouting}
            href="/plans"
            onClick={(e) => {
              if (shouldDisableJSRouting) {
                e.preventDefault();
                loadPage('/plans');
              }
            }}
            className="preflight preflight-a min-w-0 rounded-full bg-primary-green px-4 py-2 text-sm/4 font-medium text-white hover:bg-secondary-green"
          >
            Upgrade
          </a>
        </div>
      )}
      <BellNotifications />
      <UserMenu />
    </>
  ) : (
    <>
      <BellNotifications />
      <BurgerMenu>
        {/* We used array because `BurgerMenu` will auto add a wrapper to each of its children, so we cannot use `Fragment` to wrap it first */}
        {hasPermission('admin:menu') && [
          <MenuHeader key="adminH4">Admin</MenuHeader>,
          <NavItem text="Users" icon={faUsersCog} url="/admin/users" key="users" />,
          <NavItem text="Habitat info" icon={faGlobeAmericas} url="/admin/habitats" key="habitats" />,
          <NavItem text="Partners" icon={faHeart} url="/admin/partners" key="partners" />,
          <NavItem text="Camera Settings" icon={faTools} url="/admin/camera/settings" key="settings" />,
          <NavItem text="Camera Info" icon={faCctv} url="/admin/camera/info" key="info" />,
          <NavItem text="Playlists" icon={faList} url="/admin/playlists" key="playlists" />,
          <NavItem text="announcements" icon={faBullhorn} url="/admin/announcements" key="announcements" />,
          <Divider key="adminDivider" />,
        ]}

        {isFreeUser() && (
          <div className="mx-4">
            <PrimaryButton className="w-full" label="Upgrade" size="small" onClick={() => loadPage('/plans')} />
          </div>
        )}

        <MenuHeader>Account</MenuHeader>
        {hasPermission('zoo:view-partner-statistics-link') && (
          <NavItem
            text="Partner Analytics"
            icon={faAnalytics}
            onClick={async () => {
              const link = await partnerMPLinkFetcher();
              if (link) {
                window.open(link);
              }
            }}
          />
        )}
        <NavItem text="My Profile" url="/account/me" icon={faIdCardClip} />
        <NavItem text="My Album" url={`/u/${username}`} icon={faImage} />
        <NavItem text="My Animals" url="/habitats?tab=favorites" icon={faHeart} />
        <NavItem text="Account Information" url="/account/info" icon={faIdCard} />
        {hasPermission('subscription:edit') && <NavItem text="Membership" url="/account/subscription" icon={faCreditCard} />}

        <Divider />
        <MenuHeader>Help | Information</MenuHeader>
        <NavItem text="Home" url="/home" icon={faHouse} />
        <TalkNavItem />
        <NavItem text="Blog" url="https://zoolife.tv/blog" icon={faBlog} target="_blank" />
        <NavItem
          text="Gift Cards"
          url="/gift"
          icon={faGift}
          customIcon={
            emphasizeGiftCard ? (
              <img className="preflight preflight-img -ml-[3px] mb-1 mr-0.5 w-6" src={giftCardIconURL} alt="gift box" />
            ) : undefined
          }
        />
        <NavItem text="Store" url="https://zoolifetv.bigcartel.com/" icon={faCartShopping} target="_blank" />

        {isMobileSize && (
          <NavItem
            text="Install App"
            onClick={() => {
              if (habitatId) {
                dispatch(addNotification({ id: 'installApp', type: 'installApp' }));
              } else {
                dispatch(openInstallAppModal());
              }
              trackMP('add-to-home-screen-button-clicked');
            }}
            icon={faDownload}
          />
        )}
        <NavItem
          text="Feedback"
          onClick={() => {
            dispatch(openSurveyModal());
            trackMP('feedback-button-clicked');
          }}
          icon={faComment}
        />

        <NavItem text="Help" url="/help" icon={faQuestionCircle} />
        <NavItem text="Log Out" onClick={logout} icon={faSignOut} />
      </BurgerMenu>
    </>
  );
};

export default AuthenticatedHeaderContent;
