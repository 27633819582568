import { useEffect } from 'preact/hooks';

import { useViewAnnouncementMutation } from '@/hooks/announcements';
import { useReadNotificationMutation } from '@/hooks/notifications';
import { setQueryParams, useQueryParams } from '@/hooks/route';

const AutoReadNotificationsHandler = () => {
  const params = useQueryParams();
  const { mutate: viewAnnouncement } = useViewAnnouncementMutation();
  const { mutate: viewNotification } = useReadNotificationMutation();
  const announcementId = params.get('announcementId');
  const notificationId = params.get('notificationId');

  useEffect(() => {
    if (announcementId) {
      viewAnnouncement(announcementId);
      setQueryParams({ announcementId: undefined }, true);
    }
  }, [announcementId, viewAnnouncement]);

  useEffect(() => {
    if (notificationId) {
      viewNotification(notificationId);
      setQueryParams({ notificationId: undefined }, true);
    }
  }, [notificationId, viewNotification]);

  return null;
};

export default AutoReadNotificationsHandler;
