import { PrimaryButton } from 'Components/Buttons';
import { Box, Heading, Layer, Text } from 'grommet';
import { useEffect, useState } from 'preact/hooks';
import { connect } from 'react-redux';
import { buildURL } from 'Shared/fetch';
import useFetch from 'use-http';

import { useLocalStorage } from '../../hooks';
import style from './style.scss';

const defaultModalState = {
  show: false,
  appVersion: null,
  changeLog: null,
};

const WhatsNew = ({ logged }) => {
  const [appVersion, setAppVersion] = useLocalStorage('appVersion', 0);
  const [modalState, setModalState] = useState(defaultModalState);

  const { get, response } = useFetch(buildURL('/release/latest'), {
    credentials: 'include',
    cachePolicy: 'no-cache',
  });

  const { get: getLatestVersion, response: latestVersionResponse } = useFetch(buildURL('/release/check'), {
    credentials: 'include',
    cachePolicy: 'no-cache',
  });

  useEffect(() => {
    const fetchLatestRelease = async () => {
      await get();
      if (response.ok) {
        const {
          data: { version },
        } = response;
        if (version) {
          setAppVersion(version);
        }
      }
    };

    const fetchCurrentRelease = async () => {
      await getLatestVersion(`?currentVersion=${appVersion}`);
      if (latestVersionResponse.status === 200) {
        const {
          data: { changeLog, currentRelease },
        } = latestVersionResponse;
        setModalState({
          show: true,
          changeLog,
          appVersion: currentRelease,
        });
        setAppVersion(currentRelease);
      }
    };

    if (logged) {
      if (!appVersion) {
        fetchLatestRelease();
      } else {
        fetchCurrentRelease();
      }

      const interval = setInterval(fetchCurrentRelease, 5 * 60 * 1000);
      return () => {
        clearInterval(interval);
      };
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logged]);

  const onClose = () => {
    if (typeof window !== 'undefined') {
      window.location.reload(true);
    }
  };

  if (!modalState.show) {
    return null;
  }

  return (
    <Layer onEsc={onClose} onClickOutside={onClose} className={style.layer}>
      <div className={style.container}>
        <Box className={style.contentWrapper}>
          <Heading level="3" textAlign="center" color="var(--charcoal)">
            While you were away
          </Heading>
          <Text size="xlarge" textAlign="center" color="var(--descriptionGrey)">
            We’ve made a few tweaks to enhance your experience with zoolife, refresh the page to see them.
          </Text>
        </Box>
        <Box margin="30px" align="center">
          <PrimaryButton size="large" label="Refresh Page" onClick={onClose} className="w-full" />
        </Box>
      </div>
    </Layer>
  );
};

export default connect(({ user: { logged } }) => ({ logged }))(WhatsNew);
