import { faBars } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { Box, Button, Drop } from 'grommet';
import { h, toChildArray } from 'preact';
import { useCallback, useRef, useState } from 'preact/hooks';

import style from './style.scss';

/**
 * Generates a BurgerMenu component.
 *
 * @param {object} props - The properties for the component.
 * @param {string} [props.id] - The ID of the component.
 * @param {string} [props.className] - The class name of the component.
 * @param {import('preact').ComponentChildren} props.children - The children elements of the component.
 * @return {import('preact/compat').ReactElement} The rendered BurgerMenu component.
 */
const BurgerMenu = ({ id, className, children }) => {
  const ref = useRef();
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = useCallback(() => setShowMenu(!showMenu), [showMenu]);
  const closeMenu = useCallback(() => setShowMenu(false), []);

  return (
    <div id={id} ref={ref} className={clsx(style.menuContainer, className)}>
      <Button plain className={style.burgerBtn} onMouseDown={toggleMenu}>
        <FontAwesomeIcon icon={faBars} />
      </Button>
      {showMenu && (
        <Drop
          align={{ top: 'bottom' }}
          pad={{ bottom: '20px' }}
          target={ref.current}
          elevation="xlarge"
          className={style.menuDrop}
          width="100%"
          round="false"
          onClickOutside={closeMenu}
        >
          <nav>
            {toChildArray(children).map((child = null) =>
              child?.props?.clickable ? (
                <Button plain onClick={toggleMenu}>
                  {child}
                </Button>
              ) : (
                <Box>{child}</Box>
              ),
            )}
          </nav>
        </Drop>
      )}
    </div>
  );
};

export default BurgerMenu;
