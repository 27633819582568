import { Box, BoxProps, Layer } from 'grommet';

import Loader from '../Loader';

interface LoaderModalProps {
  full?: BoxProps['fill'];
  height?: string;
  width?: string;
  background?: BoxProps['background'];
  color?: string;
}

const LoaderModal = ({ full, height, width, background, color }: LoaderModalProps) => (
  <Layer animation="fadeIn" full={full} background="transparent">
    <Box fill justify="center" background={background}>
      <Loader height={height} width={width} fill={full} color={color} />
    </Box>
  </Layer>
);

export default LoaderModal;
