import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFacebookSquare, faInstagram, faTwitterSquare, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faBars } from '@fortawesome/pro-regular-svg-icons';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hasPermission, isFreeUser } from 'Components/Authorize';
import { PrimaryButton } from 'Components/Buttons';
import { openInstallAppModal } from 'Components/modals/InstallApp/actions';
import { openSurveyModal } from 'Components/modals/Survey/actions';
import ZoolifeLogo from 'Components/ZoolifeLogo';
import { Layer } from 'grommet';
import { ComponentProps, FunctionalComponent } from 'preact';
import { ReactNode } from 'preact/compat';
import { useState } from 'preact/hooks';
import { route } from 'preact-router';
import { useDispatch } from 'react-redux';
import { trackMP } from 'Shared/mp';

import { useLogout } from '@/components/Authorize/hooks';
import { useSelector } from '@/redux/helper';
import { partnerMPLinkFetcher } from '@/shared/fetch';

import style from './style.scss';

export interface MenuRowProps extends Omit<ComponentProps<'a'>, 'icon'> {
  title: string;
  icon?: IconProp;
  customIcon?: ReactNode;
}

const MenuRow: FunctionalComponent<MenuRowProps> = ({ title, icon, customIcon, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <a className={`preflight preflight-a font-medium ${style.item}`} {...rest}>
    <div className={style.text}>{title}</div>
    {customIcon || (icon && <FontAwesomeIcon icon={icon} />)}
  </a>
);

export interface ContentProps {
  onClose: () => void;
}

const Content: FunctionalComponent<ContentProps> = ({ onClose }) => {
  const dispatch = useDispatch();
  const emphasizeGiftCard = useSelector((state) => state.remoteConfig.emphasizeGiftCard);
  const giftCardIconURL = useSelector((state) => state.remoteConfig.giftCardSpecialIconURL);
  const username = useSelector((store) => store.user.username);
  const logoutHandler = useLogout();

  const routeHandler = () => {
    onClose();
  };

  return (
    <div className={style.content}>
      <div className={style.logo}>
        <ZoolifeLogo />
        <button type="button" className={style.close} onClick={onClose}>
          <FontAwesomeIcon icon={faXmark} />
        </button>
      </div>

      <div className={style.scrollContainer}>
        {!username ? (
          <div className={style.scroll}>
            <MenuRow title="Home" href="/" onClick={routeHandler} />
            <MenuRow title="Prices" href="/prices" onClick={routeHandler} />
            <MenuRow
              title="Gift Cards"
              href="/gift"
              onClick={routeHandler}
              customIcon={
                emphasizeGiftCard ? <img className="-mt-0.5 w-6" src={giftCardIconURL} alt="gift box" /> : undefined
              }
            />
            <MenuRow title="Schedules" href="/talk-schedule" onClick={routeHandler} />
            <MenuRow
              title="Blog"
              href="https://zoolife.tv/blog"
              target="_blank"
              rel="noopener noreferrer"
              onClick={routeHandler}
            />
            <MenuRow title="Login" href="/login" onClick={routeHandler} />
          </div>
        ) : (
          <div className={style.scroll}>
            {hasPermission('admin:menu') && (
              <>
                <h4>Admin</h4>
                <MenuRow title="Users" href="/admin/users" onClick={routeHandler} />
                <MenuRow title="Habitats" href="/admin/habitats" onClick={routeHandler} />
                <MenuRow title="Partners" href="/admin/partners" onClick={routeHandler} />
                <MenuRow title="Camera Settings" href="/admin/camera/settings" onClick={routeHandler} />
                <MenuRow title="Camera Info" href="/admin/camera/info" onClick={routeHandler} />
              </>
            )}
            <h4>Account</h4>
            {hasPermission('zoo:view-partner-statistics-link') && (
              <MenuRow
                title="Partner Analytics"
                onClick={async () => {
                  const link = await partnerMPLinkFetcher();
                  if (link) {
                    window.open(link);
                  }
                }}
              />
            )}
            <MenuRow title="My Profile" href="/account/me" onClick={routeHandler} />
            <MenuRow title="My Album" href={`/u/${username}`} onClick={routeHandler} />
            <MenuRow title="Account Information" href="/account/info" onClick={routeHandler} />
            {hasPermission('subscription:edit') && (
              <MenuRow title="Membership" href="/account/subscription" onClick={routeHandler} />
            )}
            <h4>Help | Information</h4>
            <MenuRow title="Help" href="/help" onClick={routeHandler} />
            <MenuRow
              title="Feedback"
              data-native
              href="#"
              onClick={() => {
                dispatch(openSurveyModal());
                trackMP('feedback-button-clicked');
                onClose();
              }}
            />
            <MenuRow
              title="Install App"
              data-native
              href="#"
              onClick={() => {
                dispatch(openInstallAppModal());
                trackMP('add-to-home-screen-button-clicked');
                onClose();
              }}
            />
            <MenuRow
              title="Blog"
              href="https://zoolife.tv/blog"
              target="_blank"
              rel="noopener noreferrer"
              onClick={routeHandler}
            />
            <MenuRow
              title="Gift Cards"
              href="/gift"
              onClick={routeHandler}
              customIcon={
                emphasizeGiftCard ? <img className="-mt-0.5 w-6" src={giftCardIconURL} alt="gift box" /> : undefined
              }
            />
            <MenuRow title="Log Out" href="#" data-native onClick={logoutHandler} />
          </div>
        )}
      </div>

      <div className={style.social}>
        <a href="https://www.facebook.com/zoolife.tv" target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={faFacebookSquare} />
        </a>
        <a href="https://twitter.com/zoolifetv" target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={faTwitterSquare} />
        </a>
        <a href="https://www.instagram.com/zoolife.tv" target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a href="https://www.youtube.com/@zoolifetv" target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={faYoutube} />
        </a>
      </div>
      {!username && (
        <PrimaryButton
          className={style.upgrade}
          label="Sign Up"
          size="large"
          onClick={() => {
            route('/signup');
            onClose();
          }}
        />
      )}
      {isFreeUser() && username && (
        <PrimaryButton
          className={style.upgrade}
          label="Upgrade Membership"
          size="large"
          onClick={() => {
            route('/plans');
            onClose();
          }}
        />
      )}
    </div>
  );
};

const Menu: FunctionalComponent = () => {
  const [showMenu, setShowMenu] = useState(false);

  const onClose = () => {
    setShowMenu(false);
  };

  return (
    <>
      <button type="button" className={style.menuButton} onClick={() => setShowMenu(true)}>
        <FontAwesomeIcon icon={faBars} />
      </button>
      {showMenu && (
        <Layer onEsc={onClose} onClickOutside={onClose} full="vertical" position="left" className={style.menuSideBar}>
          <Content onClose={onClose} />
        </Layer>
      )}
    </>
  );
};

export default Menu;
