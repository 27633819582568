import { useEffect } from 'preact/hooks';
import { route } from 'preact-router';

const Redirect = ({ to }) => {
  useEffect(() => {
    if (to) {
      const { search } = window.location;
      route(['/map', '/home'].includes(to) && search !== '' ? `${to}${search}` : to, true);
    }
  }, [to]);

  return null;
};

export default Redirect;
