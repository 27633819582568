import { ResponsiveContext } from 'grommet';
import { throttle } from 'lodash-es';
import { useContext, useEffect, useRef, useState } from 'preact/hooks';
import { useRouter } from 'preact-router';

import { isLandscape } from '../helpers';

const getSize = () => ({
  width: typeof window !== 'undefined' && window.innerWidth,
  height: typeof window !== 'undefined' && window.innerHeight,
  orientation: typeof window !== 'undefined' && (window.innerHeight > window.innerWidth ? 'portrait' : 'landscape'),
});

const calculateViewHeight = () => {
  // iPad/iPhone full height is ~98vh because they count the space under the browser controls
  // this will calculate --vh variable for actual view port for more accuracy
  if (typeof window !== 'undefined') {
    // Use `dvh` for modern browsers to prevent miscalculation of the viewport height when virtual keyboard is open
    if (CSS.supports('height', '1dvh')) {
      document.documentElement.style.setProperty('--vh', '1dvh');
    } else {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
  }
};

// TODO: should decouple window size and calling `calculateViewHeight` to
// reduce the times setting the `--vh` css variable.
export const useWindowResize = () => {
  const [size, setSize] = useState(typeof window !== 'undefined' && getSize());

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    calculateViewHeight();
    setSize(getSize());

    if (typeof window !== 'undefined') {
      let unsubscribed = false;
      const updateSize = throttle(() => {
        calculateViewHeight();
        if (unsubscribed) return;
        setSize(getSize());
      }, 400);
      window.addEventListener('resize', updateSize);
      return () => {
        unsubscribed = true;
        if (typeof window !== 'undefined') {
          window.removeEventListener('resize', updateSize);
        }
      };
    }
  }, []);

  return size;
};

/**
 * Should only need to called once per App
 * At most once per page, otherwise affect performance.
 */
export const useCSSViewportHeightFix = () => {
  useEffect(() => {
    const throttledHandler = throttle(calculateViewHeight, 400);

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', throttledHandler);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', throttledHandler);
      }
    };
  }, []);
};

export const useWindowSize = (wait = 400) => {
  const [size, setSize] = useState(() =>
    typeof window === 'undefined' ? [1024, 768] : [window.innerWidth, window.innerHeight],
  );
  useEffect(() => {
    let unsubscribed = false;
    const throttledHandler = throttle(() => {
      if (unsubscribed) return;
      setSize([window.innerWidth, window.innerHeight]);
    }, wait);
    window.addEventListener('resize', throttledHandler);
    return () => {
      unsubscribed = true;
      window.removeEventListener('resize', throttledHandler);
    };
  }, [wait]);
  return size;
};

/**
 * Call `fn` when window resize event triggered.
 * @param {(e?: UIEvent) => void} fn
 * should be a static/stable function to prevent re-render/re-listen
 * @param {{ wait?: number; shouldCallOnceBeforeListen?: boolean }} options
 */
export const useWindowResizeEffect = (fn, { wait = 400, shouldCallOnceBeforeListen = true } = {}) => {
  useEffect(() => {
    let unsubscribed = false;
    const throttledHandler = wait
      ? throttle((e) => {
          if (unsubscribed) return;
          fn(e);
        }, wait)
      : fn;
    if (shouldCallOnceBeforeListen) fn();

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', throttledHandler);
    }

    return () => {
      unsubscribed = true;
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', throttledHandler);
      }
    };
  }, [fn, wait, shouldCallOnceBeforeListen]);
};

/**
 * Call `fn` when `ref.current` is resized,
 * use `ResizeObserver` if available and `window.resize` as fallback
 * @param {(rect?: DOMRect) => void} fn
 * should be a static/stable function to prevent re-render/re-listen
 * @param {{ wait?: number; shouldCallOnceBeforeListen?: boolean }} options
 * @returns {import('preact/hooks').MutableRef} MutableRef
 */
export const useDOMRectResizeEffect = (fn, { wait = 400, shouldCallOnceBeforeListen = true } = {}) => {
  const ref = useRef(null);

  useEffect(() => {
    if (!ref.current) return undefined;

    let unsubscribed = false;
    const throttledHandler = wait
      ? throttle(() => {
          if (unsubscribed) return;
          fn(ref.current?.getBoundingClientRect());
        }, wait)
      : () => fn(ref.current?.getBoundingClientRect());

    if (shouldCallOnceBeforeListen) fn(ref.current?.getBoundingClientRect());

    if ('ResizeObserver' in window) {
      const element = ref.current;
      const resizeObserver = new ResizeObserver(throttledHandler);
      resizeObserver.observe(element);
      return () => {
        unsubscribed = true;
        resizeObserver.unobserve(element);
      };
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', throttledHandler);
    }

    return () => {
      unsubscribed = true;
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', throttledHandler);
      }
    };
  }, [fn, wait, shouldCallOnceBeforeListen]);

  return ref;
};

export const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (evt) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(evt.target)) {
        return;
      }

      handler(evt);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

// this will return false only before initial fetch
export const useIsInitiallyLoaded = (isFetching) => {
  const [loaded, setLoaded] = useState();

  useEffect(() => {
    if (loaded !== undefined && !isFetching) {
      setLoaded(true);
    } else if (isFetching) {
      setLoaded(false);
    }
  }, [isFetching]);

  return !isFetching && loaded;
};

export const useIsMobileSize = () => {
  const size = useContext(ResponsiveContext);
  return ['small', 'xsmall'].includes(size);
};

export const useIsLargerScreen = () => {
  const size = useContext(ResponsiveContext);
  return ['large'].includes(size);
};

export const useIsHabitatTabbed = () => {
  const { width: windowWidth } = useWindowResize();
  return windowWidth <= 1024;
};

export const useShowMobileControls = () => {
  const { width: windowWidth, height: windowHeight } = useWindowResize();
  return windowWidth <= 768 || (windowHeight <= 440 && isLandscape());
};

export const useOnScreen = (ref, threshold) => {
  const [isIntersecting, setIntersecting] = useState(false);
  useEffect(() => {
    const element = ref.current;
    if (!element) return undefined;
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting);
      },
      {
        threshold,
      },
    );
    observer.observe(element);
    return () => {
      observer.unobserve(element);
    };
  }, [ref, threshold]);
  return isIntersecting;
};

export const useLocalStorage = (key, initialValue) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = typeof window !== 'undefined' && window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      if (typeof window !== 'undefined') window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue];
};

export const useOutsideClick = (callback) => {
  const ref = useRef();

  useEffect(() => {
    const handleClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [callback, ref]);

  return ref;
};

export const useCurrentUrl = () => {
  const [routerCtx] = useRouter();
  return routerCtx.url;
};
