import { faComment, faCommentsQuestion, faFaceSmile, faQuestion, faUnlock } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FunctionComponent, JSX } from 'preact';

import { formatTimeAgoOrTimestamp } from '@/helpers';
import route from '@/lib/route';
import { BellNotificationType } from '@/queries/notifications/types';
import { trackMP } from '@/shared/mp';

interface NotificationProps {
  id: string;
  title: string;
  description: string;
  time: string;
  media?: string;
  viewed: boolean;
  type: BellNotificationType;
  actionURL: string;
  onClick: (id: string) => void;
}

const Notification: FunctionComponent<NotificationProps> = ({
  id,
  title,
  description,
  time,
  media,
  viewed,
  type,
  actionURL,
  onClick,
}) => {
  const header =
    type === 'Album'
      ? 'New comment'
      : type === 'Q&A'
      ? 'Q&A response'
      : type === 'UnlockHabitat'
      ? 'Unlocked habitat'
      : type === 'Comment' || type === 'MediaComment' || type === 'QuestionComment'
      ? 'New reply'
      : title;

  const icon =
    type === 'Album' || type === 'MediaComment' || type === 'Comment'
      ? faComment
      : type === 'Q&A' || type === 'QuestionComment'
      ? faCommentsQuestion
      : type === 'Question'
      ? faQuestion
      : type === 'UnlockHabitat'
      ? faUnlock
      : type === 'Reaction'
      ? faFaceSmile
      : faQuestion;

  const onClickHandler: JSX.MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    onClick(id);
    trackMP('bell-notif-click', {
      notificationId: id,
      title,
      description,
      media,
      viewed,
      type,
      actionURL,
    });

    route(actionURL, false, true);
  };

  return (
    <a
      href={actionURL}
      className="preflight preflight-a flex rounded-xl px-2 py-3 hover:bg-[#F7F7F7]"
      onClick={onClickHandler}
    >
      <div className="preflight relative mr-2">
        <div className="preflight flex h-10 w-10 items-center justify-center rounded-full border border-primary-green">
          <span className="sr-only">{type}</span>
          <FontAwesomeIcon icon={icon} color="var(--primaryGreen)" />
        </div>

        {!viewed && <i className="absolute -right-1 -top-1 h-2 w-2 rounded-full bg-blue-1" />}
      </div>

      <div className="grow">
        <div className="flex items-center justify-between gap-2">
          <h4 className="preflight preflight-h4 text-sm font-semibold">{header}</h4>
          <span className="text-right text-xs text-grey-1 opacity-60">{formatTimeAgoOrTimestamp(new Date(time), true)}</span>
        </div>
        <p className="preflight preflight-p mt-1 text-xs text-grey-6">{description}</p>
      </div>
    </a>
  );
};

export default Notification;
