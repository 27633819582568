import { faIdCard } from '@fortawesome/pro-regular-svg-icons';
import {
  faAnalytics,
  faBlog,
  faBullhorn,
  faCalendarDay,
  faCartShopping,
  faCctv,
  faComment,
  faCreditCard,
  faGift,
  faGlobeAmericas,
  faHeart,
  faIdCardClip,
  faImage,
  faList,
  faQuestionCircle,
  faSignOut,
  faTools,
  faUsersCog,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AnimalIcon from 'Components/AnimalIcon';
import { hasPermission } from 'Components/Authorize';
import { Box, Drop, Text } from 'grommet';
import { useCallback, useRef, useState } from 'preact/hooks';
import { useDispatch } from 'react-redux';
import { trackMP } from 'Shared/mp';

import { useSelector } from '@/redux/helper';
import { unsetUserData } from '@/redux/slices/user/actions';
import { partnerMPLinkFetcher } from '@/shared/fetch';

import { useLogout } from '../../Authorize/hooks';
import { openSurveyModal } from '../../modals/Survey/actions';
import style from './style.scss';

const MenuRow = ({ title, icon, customIcon, ...rest }) => (
  <a
    className="preflight preflight-a flex h-9 w-full cursor-pointer items-center px-4 font-medium hover:bg-grey-5"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
  >
    <span className="inline-flex w-5">{customIcon || <FontAwesomeIcon icon={icon} color="var(--grey)" />}</span>
    <div className="ml-3">
      <Text color="var(--grey)" size="large">
        {title}
      </Text>
    </div>
  </a>
);

const Menu = () => {
  const buttonRef = useRef(null);
  const [showMenu, setShowMenu] = useState(false);
  const emphasizeGiftCard = useSelector((state) => state.remoteConfig.emphasizeGiftCard);
  const giftCardIconURL = useSelector((state) => state.remoteConfig.giftCardSpecialIconURL);
  const username = useSelector((state) => state.user.username);

  const dispatch = useDispatch();
  const onLogout = useCallback(() => {
    setShowMenu(false);
    dispatch(unsetUserData());
  }, [dispatch]);
  const logout = useLogout(onLogout);

  const surveyHandler = (e) => {
    e.preventDefault();
    setShowMenu(false);
    dispatch(openSurveyModal());
    trackMP('feedback-button-clicked');
  };

  return (
    <div className={style.menuContainer}>
      <Box
        pad="8px"
        margin={{ right: '10px' }}
        align="center"
        justify="start"
        ref={buttonRef}
        // using onclick for cursor pointer,
        // and onMouseDown to prevent reopening on avatar click
        onClick={() => {}}
        onMouseDown={() => setShowMenu(!showMenu)}
      >
        <AnimalIcon width={40} />
      </Box>

      {showMenu && (
        <Drop
          align={{ top: 'bottom', right: 'right' }}
          target={buttonRef.current}
          elevation="xlarge"
          className={style.drop}
          onClickOutside={() => setShowMenu(false)}
          onClick={() => setShowMenu(false)}
          width={{ min: '280px' }}
        >
          <div className={style.wrapper}>
            {hasPermission('admin:menu') && (
              <>
                <h4>Admin</h4>
                <MenuRow title="Users" icon={faUsersCog} href="/admin/users" />
                <MenuRow title="Habitats" icon={faGlobeAmericas} href="/admin/habitats" />
                <MenuRow title="Partners" icon={faHeart} href="/admin/partners" />
                <MenuRow title="Camera Settings" icon={faTools} href="/admin/camera/settings" />
                <MenuRow title="Camera Info" icon={faCctv} href="/admin/camera/info" />
                <MenuRow title="Playlists" icon={faList} href="/admin/playlists" />
                <MenuRow title="Announcements" icon={faBullhorn} href="/admin/announcements" />
                <hr />
              </>
            )}
            <h4>Account</h4>
            {hasPermission('zoo:view-partner-statistics-link') && (
              <MenuRow
                title="Partner Analytics"
                icon={faAnalytics}
                onClick={async () => {
                  const link = await partnerMPLinkFetcher();
                  if (link) {
                    window.open(link);
                  }
                }}
              />
            )}
            <MenuRow title="My Profile" icon={faIdCardClip} href="/account/me" />
            <MenuRow title="My Album" icon={faImage} href={`/u/${username}`} />
            <MenuRow title="My Animals" icon={faHeart} href="/habitats?tab=favorites" />
            <MenuRow title="Account Information" icon={faIdCard} href="/account/info" />
            {hasPermission('subscription:edit') && (
              <MenuRow title="Membership" icon={faCreditCard} href="/account/subscription" />
            )}
            <hr />

            <h4>Help | Information</h4>
            <MenuRow title="Events" icon={faCalendarDay} href="/schedule" />
            <MenuRow title="Blog" icon={faBlog} href="https://zoolife.tv/blog" target="_blank" rel="noopener noreferrer" />
            <MenuRow
              title="Gift Cards"
              icon={faGift}
              href="/gift"
              customIcon={emphasizeGiftCard ? <img className="w-6" src={giftCardIconURL} alt="gift box" /> : undefined}
            />
            <MenuRow
              title="Store"
              icon={faCartShopping}
              href="https://zoolifetv.bigcartel.com/"
              target="_blank"
              rel="noopener noreferrer"
            />
            <MenuRow title="Feedback" icon={faComment} href="#" onClick={surveyHandler} />
            <MenuRow title="Help" icon={faQuestionCircle} href="/help" />
            <MenuRow
              title="Log Out"
              icon={faSignOut}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                logout();
              }}
            />
          </div>
        </Drop>
      )}
    </div>
  );
};

export default Menu;
