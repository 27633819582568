import { faBell } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RoundCount from 'Components/RoundCount';
import { Drop } from 'grommet';
import { memo } from 'preact/compat';
import { useRef, useState } from 'preact/hooks';

import { useUnreadAnnouncementsCount } from '@/hooks/announcements';
import { useShouldBellRing, useUnreadBellNotificationCount } from '@/hooks/notifications';

import Notifications from './Notifications';
import style from './style.scss';

const BellNotifications = () => {
  const [showList, setShowList] = useState(false);
  const { data: unreadAnnouncements } = useUnreadAnnouncementsCount();
  const { data: unreadNotifications } = useUnreadBellNotificationCount();
  const unread = (unreadNotifications ?? 0) + (unreadAnnouncements ?? 0);
  const ref = useRef();
  const bellRef = useRef(null);
  const shouldBellRing = useShouldBellRing(unread, bellRef);

  const toggleHandler = () => {
    setShowList(!showList);
  };

  return (
    <div ref={ref} className={style.bellNotification}>
      <button type="button" onMouseDown={toggleHandler} disabled={showList}>
        {unread > 0 && <RoundCount count={unread} className="-right-1" />}
        <div
          ref={bellRef}
          className={unread > 0 && shouldBellRing ? 'animate-[ringingBell_2s_1s_ease-in-out] [transform-origin:50%_0]' : ''}
        >
          <FontAwesomeIcon icon={faBell} />
        </div>
      </button>

      {showList && ref.current && (
        <Drop
          align={{ top: 'bottom', right: 'right' }}
          target={ref.current}
          elevation="xlarge"
          className={style.bellDropContainer}
          plain
          round="false"
          stretch
          onClickOutside={toggleHandler}
        >
          <div className="h-[min(600px,80vh)] w-[min(355px,calc(100vw-20px))]">
            <Notifications />
          </div>
        </Drop>
      )}
    </div>
  );
};

export default memo(BellNotifications);
