import { faLoader } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { Box, BoxProps } from 'grommet';
import { FunctionComponent } from 'preact';

import style from './style.scss';

const Loader: FunctionComponent<{
  color?: string;
  fill?: BoxProps['fill'];
  className?: string;
  absolute?: boolean;
  width?: string;
  height?: string;
}> = ({ color = 'var(--mediumGrey)', fill, className, absolute, width = '100px', height = '100px' }) => (
  <Box fill={fill} justify="center" align="center" className={clsx(style.animation, className, absolute && style.absolute)}>
    <FontAwesomeIcon icon={faLoader} spin style={{ color, width, height }} />
  </Box>
);

export default Loader;
