import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryButton } from 'Components/Buttons';
import { Box, Button, CheckBox, Heading, Layer, ResponsiveContext, Text } from 'grommet';
import { h } from 'preact';
import { useContext, useState } from 'preact/hooks';
import { useDispatch } from 'react-redux';
import { buildURL } from 'Shared/fetch';
import useFetch from 'use-http';

import { useSelector } from '@/redux/helper';
import { setTermsAccepted } from '@/redux/slices/user/actions';

import { TERMS_PDF_URL } from './constants';
import { closeTermsModal } from './redux/actions';

const TermsAndConditions = () => {
  const dispatch = useDispatch();
  const logged = useSelector((state) => state.user.logged);
  const { isOpen, isCloseDisabled } = useSelector((state) => state.modals.terms);
  const size = useContext(ResponsiveContext);
  const [acceptedTerms, setAcceptedTerms] = useState(true);
  const [acceptedPrivacy, setAcceptedPrivacy] = useState(true);

  const { post, response, error, loading } = useFetch(buildURL('/users/accept-terms-and-services'), {
    credentials: 'include',
    cachePolicy: 'no-cache',
  });

  const onCloseAction = () => {
    dispatch(closeTermsModal());
  };

  const onContinue = async () => {
    await post();
    if (response.ok) {
      onCloseAction();
      dispatch(setTermsAccepted());
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Layer
      responsive
      onEsc={!isCloseDisabled ? onCloseAction : undefined}
      onClickOutside={!isCloseDisabled ? onCloseAction : undefined}
    >
      <Box>
        <Box width="large" direction="row" align="center" as="header" elevation="small" justify="between">
          <Heading level="2" margin={{ vertical: 'medium', horizontal: 'large' }}>
            Terms & Privacy Notice
          </Heading>
          {!isCloseDisabled && (
            <Button
              plain
              margin="medium"
              onClick={onCloseAction}
              icon={<FontAwesomeIcon style={{ fontSize: size !== 'small' ? '28px' : '20px' }} icon={faTimes} />}
            />
          )}
        </Box>

        {logged && (
          <Box pad="large" elevation="small" as="footer">
            <Box direction="row">
              <CheckBox checked={acceptedTerms} onChange={() => setAcceptedTerms(!acceptedTerms)} />
              <Text margin={{ left: 'small' }} size="xlarge">
                I agree to zoolife’s{' '}
                <a data-native target="_blank" rel="noopener noreferrer" href={TERMS_PDF_URL}>
                  Terms &amp; Privacy
                </a>
              </Text>
            </Box>

            <Box align="end" margin={{ top: 'small' }}>
              <PrimaryButton
                primary
                onClick={onContinue}
                loading={loading}
                size="large"
                disabled={!acceptedTerms || !acceptedPrivacy}
                label="Continue"
              />
              {error && (
                <Box pad="small">
                  <Text color="status-error">Something went wrong. Please try again.</Text>
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Layer>
  );
};

export default TermsAndConditions;
