import AppLoader from 'Components/AppLoader';
import { memo } from 'preact/compat';
import { useCallback, useMemo, useState } from 'preact/hooks';
import { useDispatch } from 'react-redux';
import { Provider as UseFetchProvider } from 'use-http';

import { getDeviceType, removeRedirectURLLocalStorage, setRedirectURLLocalStorage } from '@/helpers';
import { useSelector } from '@/redux/helper';
import { unsetUserData } from '@/redux/slices/user/actions';
import { authRedirect } from '@/shared/fetch';

import ErrorPage from '../ErrorPage';
import { hasPermission } from '.';
import { useFetchUserIfNeeded } from './hooks';

const AuthGuard = ({
  permission,
  adminOnly,
  phoneOnly,
  // display fallback component if user is not authorized
  fallback,
  children,
  redirectTo,
  publicPath,
  // we need to pass this to children
  // because we'll have props added by preact router or some other parent
  ...props
}) => {
  const dispatch = useDispatch();
  const role = useSelector((state) => state.user.role);
  const sessionChecked = useSelector((state) => state.user.sessionChecked);
  const active = useSelector((state) => state.user.subscription?.active);

  const [error, setError] = useState();

  const authorized = useMemo(
    () => hasPermission(permission),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [permission, sessionChecked, active],
  );
  if (authorized) {
    removeRedirectURLLocalStorage();
  } else {
    setRedirectURLLocalStorage();
  }

  const useFetchOptions = useMemo(
    () => ({
      interceptors: {
        response: async ({ response }) => {
          if (response.status === 401) {
            dispatch(unsetUserData());
            if (publicPath) {
              authRedirect(publicPath);
            } else {
              authRedirect();
            }
          }
          return response;
        },
      },
    }),
    [dispatch, publicPath],
  );

  useFetchUserIfNeeded(
    useCallback(
      (response) => {
        if (response.status === 204) {
          authRedirect(publicPath);
        } else if (response.status !== 200) {
          setError(true);
        }
      },
      [publicPath],
    ),
  );

  if ((!adminOnly && !permission && !phoneOnly) || (adminOnly && permission && !phoneOnly)) {
    throw new Error('AuthGuard expects either "adminOnly", "permission", "phoneOnly", "guestOnly" prop.');
  }

  if (error) {
    return <ErrorPage error="500" message="Oops! Something went wrong." url={props.path} />;
  }

  if (
    (sessionChecked && role === 'admin' && adminOnly) ||
    (sessionChecked && !adminOnly && authorized) ||
    (sessionChecked && phoneOnly && getDeviceType() === 'phone' && authorized)
  ) {
    return <UseFetchProvider options={useFetchOptions}>{children}</UseFetchProvider>;
  }

  if (sessionChecked && !authorized) {
    if (redirectTo) {
      return authRedirect(redirectTo);
    }

    return fallback || <ErrorPage error="403" message="Sorry, you don't have access to this page." url={props.path} />;
  }

  return <AppLoader />;
};

export default memo(AuthGuard);
