import { cloneElement } from 'preact';
import { memo } from 'preact/compat';
import { useEffect, useRef } from 'preact/hooks';
import ResizeObserver from 'resize-observer-polyfill';

import { updateIframeHeight } from './helpers';

const IframeView = ({ linkTarget, children, showTrailers }) => {
  const ref = useRef();
  const resizeObserver = useRef();
  const { searchParams } = new URL(document.location);

  useEffect(() => {
    window.iframeContext = {
      ref: ref.current,
      linkTarget: searchParams.get('linkTarget') || linkTarget || '_parent',
      showCollapsed: searchParams.get('showCollapsed'),
      showTrailers: searchParams.get('showTrailers') || showTrailers,
    };
  }, [searchParams, linkTarget, showTrailers]);

  useEffect(() => {
    if (ref.current.base && !resizeObserver.current) {
      const observer = new ResizeObserver(() => updateIframeHeight(ref.current));

      observer.observe(ref.current.base);
      resizeObserver.current = observer;
    }

    return () => {
      if (resizeObserver.current) {
        resizeObserver.current.disconnect();
        resizeObserver.current = null;
      }
    };
  }, [ref.current?.base]);

  useEffect(() => {
    const iframeMessageHandler = (event) => {
      const { data } = event;

      if (data?.action === 'getHeight') {
        updateIframeHeight(ref.current);
      }
    };

    if (ref?.current) {
      window.addEventListener('message', iframeMessageHandler);
    }

    return () => {
      window.removeEventListener('message', iframeMessageHandler);
    };
  }, [ref]);

  return cloneElement(children, { ref });
};

export default memo(IframeView);
