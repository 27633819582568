import { faBell, faCalendarWeek, faClapperboardPlay, faMapLocation } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FunctionalComponent } from 'preact';
import { useRef } from 'preact/hooks';
import { getCurrentUrl, Link } from 'preact-router';

import RoundCount from '@/components/RoundCount';
import { useUnreadAnnouncementsCount } from '@/hooks/announcements';
import { useShouldBellRing, useUnreadBellNotificationCount } from '@/hooks/notifications';

import style from './style.scss';

const activeClass = (path: string) => (getCurrentUrl().includes(path) ? style.active : undefined);

const MobileTabs: FunctionalComponent = () => {
  const { data: unreadAnnouncements } = useUnreadAnnouncementsCount();
  const { data: unreadNotifications } = useUnreadBellNotificationCount();
  const bellRef = useRef<HTMLDivElement>(null);
  const unread = (unreadAnnouncements ?? 0) + (unreadNotifications ?? 0);
  const shouldBellRing = useShouldBellRing(unread, bellRef);

  return (
    <nav className={style.mobileTabs}>
      <ul>
        <li>
          <Link className={activeClass('/highlights')} href="/highlights">
            <FontAwesomeIcon icon={faClapperboardPlay} />
          </Link>
        </li>
        <li>
          <Link data-tour-id="habitatsTab" className={activeClass('/home')} href="/home">
            <FontAwesomeIcon icon={faMapLocation} />
          </Link>
        </li>
        <li>
          <Link className={activeClass('schedule')} href="/schedule">
            <div className="relative">
              <FontAwesomeIcon icon={faCalendarWeek} />
            </div>
          </Link>
        </li>
        <li>
          <Link className={activeClass('/notifications')} href="/notifications">
            <div className="relative">
              <div
                ref={bellRef}
                className={
                  unread > 0 && shouldBellRing ? 'animate-[ringingBell_2s_1s_ease-in-out] [transform-origin:50%_0]' : ''
                }
              >
                <FontAwesomeIcon icon={faBell} />
              </div>
              {unread > 0 && <RoundCount count={unread} className="bg-text-error-red" />}
            </div>
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default MobileTabs;
