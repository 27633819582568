import { h } from 'preact';
import { memo } from 'preact/compat';
import { useMemo } from 'preact/hooks';
import { getIconKeys, getIconUrl } from 'Shared/profileIcons';

import { useSelector } from '@/redux/helper';

import style from './style.scss';

const AnimalIcon = ({ animalIcon, color, width = 30 }) => {
  const userIcon = useSelector((state) => state.user?.profile.animalIcon);
  const userColor = useSelector((state) => state.user?.profile.color);
  const userIconUrl = useMemo(() => {
    // in case of old solution where we were saving URLs,
    // initial value will be undefined after a user signup
    if (userIcon?.endsWith('.svg')) {
      return userIcon;
    }
    // getIconUrl(getIconKeys()[0]) is defensive part in case 'userIcon' is not found
    return getIconUrl(userIcon) || getIconUrl(getIconKeys()[0]);
  }, [userIcon]);

  const animalIconUrl = useMemo(() => {
    if (!animalIcon) {
      return null;
    }
    // in case of old solution where we were saving URLs,
    // initial value will be undefined after a user signup
    if (animalIcon?.endsWith('.svg')) {
      return animalIcon;
    }
    // getIconUrl(getIconKeys()[0]) is defensive part in case 'userIcon' is not found
    return getIconUrl(animalIcon) || getIconUrl(getIconKeys()[0]);
  }, [animalIcon]);

  return (
    <div
      style={{
        backgroundColor: color || userColor,
        width,
        height: width,
        minWidth: width,
        minHeight: width,
      }}
      className={style.animalIcon}
    >
      <img src={animalIconUrl || userIconUrl} alt="animal" />
    </div>
  );
};

export default memo(AnimalIcon);
