import { useInfiniteQuery, useQuery, useQueryClient } from '@tanstack/react-query';
import { debounce } from 'lodash-es';
import { TargetedEvent } from 'preact/compat';
import { useEffect, useMemo } from 'preact/hooks';

import { OutlineButton } from '@/components/Buttons';
import Loader from '@/components/Loader';
import { useReadAllNotifications, useReadNotificationMutation } from '@/hooks/notifications';
import { getAnnouncementsQueryOptions } from '@/queries/announcements';
import { getNotificationsInfiniteQueryOptions } from '@/queries/notifications';
import { useSelector } from '@/redux/helper';

import Announcements from '../Announcements';
import EmptyNotifications from './EmptyNotifiications';
import Notification from './Notification';

const Notifications = () => {
  const queryClient = useQueryClient();
  const userId = useSelector((store) => store.user.userId);
  const sessionChecked = useSelector((state) => state.user.sessionChecked);

  const {
    data: announcements,
    isLoading: isLoadingAnnouncements,
    isFetching: isFetchingAnnouncements,
  } = useQuery({
    ...getAnnouncementsQueryOptions(),
    enabled: sessionChecked && !!userId,
  });
  const {
    data: { list: notifications = undefined } = {},
    fetchNextPage,
    hasNextPage,
    isLoading: isLoadingNotifications,
    isFetching: isFetchingNotifications,
  } = useInfiniteQuery(getNotificationsInfiniteQueryOptions());
  const { mutate: markAsRead, isPending: loading } = useReadAllNotifications();
  const { mutate: viewNotification } = useReadNotificationMutation();

  const hasNotifications = (announcements?.length ?? 0) > 0 || (notifications?.length ?? 0) > 0;
  const isLoading = isLoadingAnnouncements || isLoadingNotifications;
  const isFetching = isFetchingAnnouncements || isFetchingNotifications;

  console.log({ isFetching });

  const onScrollHandler = useMemo(
    () =>
      debounce(
        (event: TargetedEvent<HTMLDivElement>) => {
          const target = event.target as HTMLDivElement;
          if (
            target &&
            target.scrollHeight - (target.scrollTop + target.clientHeight) < 10 &&
            hasNextPage &&
            !isLoadingNotifications
          ) {
            fetchNextPage();
          }
        },
        120,
        { trailing: true },
      ),
    [fetchNextPage, hasNextPage, isLoadingNotifications],
  );

  // Reset notifications query to prevent it fetching multiple pages when re-mounting
  useEffect(
    () => () => {
      queryClient.resetQueries({ queryKey: getNotificationsInfiniteQueryOptions().queryKey });
    },
    [queryClient],
  );

  return (
    <section className="flex h-full w-full flex-col bg-white">
      <div className="flex items-center justify-between p-4">
        <h4 className="preflight preflight-h4 font-semibold">All Notifications</h4>
        {hasNotifications && (
          <OutlineButton loading={loading} size="medium" label="Mark as read" onClick={() => markAsRead()} />
        )}
      </div>
      <div className="customScrollBar grey min-h-0 grow" onScroll={onScrollHandler}>
        {isLoading ? (
          <Loader fill />
        ) : !hasNotifications ? (
          <EmptyNotifications />
        ) : (
          <>
            <Announcements data={announcements} />

            <ul className="preflight preflight-ul px-2 pb-6">
              {notifications?.map((item) => (
                <li key={item._id}>
                  <Notification
                    id={item._id}
                    title={item.title}
                    description={item.body}
                    time={item.createdAt}
                    media={item.previewImage}
                    viewed={item.isViewed}
                    actionURL={item.actionURL}
                    type={item.type}
                    onClick={viewNotification}
                  />
                </li>
              ))}
              {isFetching && (
                <li>
                  <Loader width="24px" height="24px" className="mt-2" />
                </li>
              )}
            </ul>
          </>
        )}
      </div>
    </section>
  );
};

export default Notifications;
