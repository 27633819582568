import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { faBullhorn, faFilm } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MouseEventHandler } from 'preact/compat';

import { useViewAnnouncementMutation } from '@/hooks/announcements';
import route from '@/lib/route';
import { AnnouncementWithViewed } from '@/queries/announcements/types';

interface AnnouncementItemProps {
  item: AnnouncementWithViewed;
}

const AnnouncementItem = ({ item }: AnnouncementItemProps) => {
  const isSameOrigin =
    item.actionURL.startsWith('/') || (typeof window !== 'undefined' && item.actionURL.startsWith(window.location.origin));

  const icon = item.type === 'text' ? faBullhorn : item.type === 'event' ? faCalendar : faFilm;

  const { mutate } = useViewAnnouncementMutation();

  const onClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();

    mutate(item._id);
    route(item.actionURL, false, true);
  };

  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      data-native
      className="preflight preflight-a flex rounded-xl border-2 border-primary-green px-2 py-3 hover:bg-beige-light"
      href={item.actionURL}
      target={isSameOrigin ? undefined : '_blank'}
      rel={isSameOrigin ? undefined : 'noopener noreferrer'}
      onClick={onClick}
    >
      <div className="preflight relative mr-2">
        <div className="preflight flex h-10 w-10 items-center justify-center rounded-full bg-primary-green">
          <span className="sr-only">{item.type}</span>
          <FontAwesomeIcon icon={icon} color="var(--white)" />
        </div>

        {!item.isViewed && <i className="absolute -right-1 -top-1 h-2 w-2 rounded-full bg-blue-1" />}
      </div>

      <div className="grow">
        <h4 className="preflight preflight-h4 text-sm font-semibold">{item.title}</h4>
        <p className="preflight preflight-p mt-1 text-xs text-grey-6">{item.body}</p>
      </div>
    </a>
  );
};

export default AnnouncementItem;
