import { infiniteQueryOptions, queryOptions } from '@tanstack/react-query';
import { compareDesc } from 'date-fns';

import { buildURL, post } from '@/shared/fetch';

import { CursorPagination } from '../types';
import { getInfiniteQueryFn, getQueryFn } from '../utils';
import { BellNotification } from './types';

export const readAllNotificationsMutationFn = () => {
  return post(buildURL('/notifications/viewed'));
};

export const readNotificationMutationFn = (notificationId: string) => {
  return post(buildURL(`/notifications/${notificationId}/viewed`));
};

export const getUnreadNotificationCountQueryOption = () =>
  queryOptions({
    queryKey: ['notifications', 'unread'],
    queryFn: getQueryFn<{ unread: number }>('/notifications/unread'),
    select: ({ unread }) => unread,
  });

export const getNotificationsInfiniteQueryOptions = () =>
  infiniteQueryOptions({
    queryKey: ['notifications', 'list'],
    queryFn: getInfiniteQueryFn<CursorPagination<BellNotification>>('/notifications'),
    initialPageParam: '',
    getNextPageParam: (lastPage) => lastPage.nextCursor,
    select: ({ pages }) =>
      pages.length > 0
        ? ({
            list: pages
              .flatMap((page) => page.list)
              .sort((a, b) => compareDesc(new Date(a.createdAt), new Date(b.createdAt))),
            nextCursor: pages[pages.length - 1].nextCursor,
          } as CursorPagination<BellNotification>)
        : undefined,
  });
