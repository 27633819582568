declare global {
  interface Window {
    iframeContext?: {
      ref: any;
    };
  }
}

export const updateIframeHeight = (elemRef?: any) => {
  const elm = elemRef?.base || window.iframeContext?.ref?.base;

  if (elm) {
    window.parent.postMessage({ setHeight: elm.offsetHeight }, '*');
  }
};

export default updateIframeHeight;
