import { useEffect, useRef } from 'preact/hooks';
import { route } from 'preact-router';
import { useDispatch } from 'react-redux';
import useFetch, { CachePolicies, Res } from 'use-http';

import { identifyUser, removeRedirectURLLocalStorage } from '@/helpers';
import { useSelector } from '@/redux/helper';
import { setSessionChecked, setUserData, unsetUserData } from '@/redux/slices/user/actions';
import { buildURL, patch } from '@/shared/fetch';
import { getClientId } from '@/shared/ga';
import { trackMP } from '@/shared/mp';

export const useLogout = (onLogout?: () => void) => {
  const { post, response } = useFetch(buildURL('/users/logout'), {
    credentials: 'include',
    cachePolicy: CachePolicies.NO_CACHE,
  });

  return async () => {
    await post();
    if (response.ok) {
      onLogout?.();
      localStorage.removeItem('giftModal');
      removeRedirectURLLocalStorage();
      route('/login');
    }
  };
};

const updateUserGAClientIds = async (user: { gaClientIds?: string[]; _id: string }) => {
  const clientId = await getClientId();
  const userClientIds = user.gaClientIds ?? [];
  if (!userClientIds.includes(clientId)) {
    await patch(buildURL(`/users/${user._id}/clientId`), {
      gaClientIds: [...userClientIds, clientId],
    });
  }
};

export const useFetchUserIfNeeded = (onResponse?: (response: Res<any>) => void) => {
  const { get, response } = useFetch(buildURL('/users/user'), {
    credentials: 'include',
    cachePolicy: CachePolicies.NO_CACHE,
  });
  const dispatch = useDispatch();
  const sessionChecked = useSelector((state) => state.user.sessionChecked);

  const onResponseRef = useRef(onResponse);

  useEffect(() => {
    onResponseRef.current = onResponse;
  }, [onResponse]);

  useEffect(() => {
    if (sessionChecked) {
      return undefined;
    }

    const loadUserData = async () => {
      await get();

      if (response.status === 200) {
        const user = response.data;
        dispatch(setUserData(user));
        updateUserGAClientIds(user);
        identifyUser(user);
        trackMP('login', { method: 'automatic' });
      } else if (response.status === 204) {
        dispatch(unsetUserData());
      } else {
        dispatch(setSessionChecked());
      }

      onResponseRef.current?.(response);
    };

    const id = setTimeout(loadUserData, 500);
    return () => clearTimeout(id);
  }, [get, response, sessionChecked, dispatch]);
};
