import { ReactNode } from 'preact/compat';
import { useEffect, useMemo } from 'preact/hooks';
import { useDispatch } from 'react-redux';
import { ABTestStoreProvider, getActiveVariant, setActiveVariant, subscribe } from 'zl-shared/dist/ab-test';

import { useSelector, useStore } from '@/redux/helper';
import { setDeviceVariant, unsetEndedExperiments } from '@/redux/slices/deviceSettings/actions';
import { setUserData, updateABTestVariants } from '@/redux/slices/user/actions';
import { buildURL } from '@/shared/fetch';
import { trackMP } from '@/shared/mp';

const saveToRemoteStorage = (variants: Record<string, string>, replace = false) =>
  fetch(buildURL('/users/me/abTestVariants'), {
    method: replace ? 'PUT' : 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(variants),
  });

/**
 * Used to propagate changes from API response to `abTest` memory store
 * Also update the newly found variants in device store to backend
 */
const ABTestVariantUpdater = () => {
  const dispatch = useDispatch();
  const logged = useSelector((state) => state.user.logged);
  const abTestVariants = useSelector((state) => state.user.abTestVariants);
  const localABTestVariants = useSelector((state) => state.deviceSettings.abTestVariants);

  // load remote saved variants into memory
  useEffect(() => {
    if (abTestVariants) {
      // activate remote saved variants
      Object.entries(abTestVariants).forEach(([key, value]) => {
        // only update if the value is different and it is valid
        if (value !== getActiveVariant(key)) {
          // skip save since we just want to update the active variant in memory
          setActiveVariant(key, value, true);
        }
      });
    }
  }, [abTestVariants]);

  // save newly found variants from device store to backend
  useEffect(() => {
    if (!logged) {
      return;
    }
    const newlyFoundVariants = Object.entries(localABTestVariants)
      .map((entry) => (abTestVariants?.[entry[0]] != null ? null : entry))
      .filter(Boolean) as [string, string][];
    if (newlyFoundVariants.length > 0) {
      saveToRemoteStorage(Object.fromEntries(newlyFoundVariants), !abTestVariants)
        .then(() => fetch(buildURL('/users/user')))
        .then((resp) => resp.json())
        .then((user) => dispatch(setUserData(user)))
        .catch(console.error);
    }
  }, [logged, abTestVariants, localABTestVariants, dispatch]);

  return null;
};

const ABTestProvider = ({ children }: { children: ReactNode }) => {
  const store = useStore();

  const abTestStore = useMemo(() => {
    const getItem = async (key: string) => {
      const {
        user: { logged, abTestVariants: userABTestVariants },
        deviceSettings: { abTestVariants },
      } = store.getState();
      const userVariant = logged ? userABTestVariants?.[key] : undefined;
      return userVariant ?? abTestVariants?.[key];
    };
    const setItem = async (key: string, value: string) => {
      // save to device store
      store.dispatch(setDeviceVariant({ experiment: key, variant: value }));
      // only update user's redux store and backend if logged in
      if (store.getState().user.logged) {
        const variants = { [key]: value };
        store.dispatch(updateABTestVariants(variants));
        await saveToRemoteStorage(variants).catch(console.error);
      }
    };
    return { getItem, setItem };
  }, [store]);

  // capturing all the A/B testing experiments and their variants to mixpanel automatically
  useEffect(
    () =>
      subscribe('play', (experimentName, variantName) => {
        trackMP('$experiment_started', { 'Experiment name': experimentName, 'Variant name': variantName });
      }),
    [],
  );

  // remove ended experiments from device store to release disk space
  useEffect(() => {
    store.dispatch(unsetEndedExperiments());
  }, [store]);

  return (
    <ABTestStoreProvider store={abTestStore}>
      {children}
      <ABTestVariantUpdater />
    </ABTestStoreProvider>
  );
};

export default ABTestProvider;
