import { buildURL, patch, post, put } from '@/shared/fetch';

import { PatchUserProfileRequest, UpdateMarketingOptInRequest } from './types';

export const patchUserProfileMutationFn = async (args: PatchUserProfileRequest) => {
  const { timeZone: timezone } = Intl.DateTimeFormat().resolvedOptions();
  return patch(buildURL('/users/profile'), {
    ...args,
    timezone,
  });
};

export const putUserFreeHabitatsMutationFn = async (freeHabitatIds: string[]) => {
  return put(buildURL('/users/subscription/freeHabitats'), { freeHabitatIds });
};

export const postUserMarketingOptInMutationFn = async (args: UpdateMarketingOptInRequest) => {
  return post(buildURL('/users/account/marketing'), args);
};
