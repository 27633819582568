import { faSearch, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Search from 'Components/Header/Search';
import { FunctionalComponent, h } from 'preact';
import { useCallback, useState } from 'preact/hooks';

import style from './style.scss';

const MobileSearch: FunctionalComponent = () => {
  const [showSearchBar, setShowSearchBar] = useState(false);

  const onClickHandler = () => {
    setShowSearchBar(true);
    setTimeout(() => {
      // @ts-ignore
      document.querySelector(`#searchContainer input`)?.focus();
    }, 100);
  };

  const onDismiss = useCallback(() => setShowSearchBar(false), []);

  return (
    <>
      <button type="button" className={style.searchButton} onClick={onClickHandler}>
        <FontAwesomeIcon icon={faSearch} />
      </button>
      {showSearchBar && (
        <div className={style.searchContainer} id="searchContainer">
          <Search className={style.searchBar} onClickSuggestion={onDismiss} />
          <button type="button" className={style.close} onClick={onDismiss}>
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>
      )}
    </>
  );
};

export default MobileSearch;
