import { AnnouncementWithViewed } from '@/queries/announcements/types';

import AnnouncementItem from './AnnouncementItem';

const Announcements = ({ data }: { data?: AnnouncementWithViewed[] }) => {
  if (!data) {
    return null;
  }

  return (
    <ul className="preflight preflight-ul mb-2 flex flex-col gap-2 px-2 empty:hidden">
      {data?.map((item) => (
        <li key={item._id}>
          <AnnouncementItem item={item} />
        </li>
      ))}
    </ul>
  );
};

export default Announcements;
