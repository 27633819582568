import './style/globalStyle.scss';
import '@fortawesome/fontawesome-svg-core/styles.css';

import { config as faConfig } from '@fortawesome/fontawesome-svg-core';
import { Grommet } from 'grommet';
import { grommet } from 'grommet/themes';
import { deepMerge } from 'grommet/utils';
import { useCallback, useEffect, useErrorBoundary } from 'preact/hooks';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import ABTestProvider from '@/components/ab-test/ABTestProvider';
import GlobalsContextProvider from '@/components/GlobalsContextProvider';
import Main from '@/components/Main';
import RemoteConfigUpdater from '@/components/RemoteConfigUpdater';
import persistor from '@/redux/persistor';
import store from '@/redux/store';
import { initializeGA } from '@/shared/ga';
import { initializeMP } from '@/shared/mp';

import VideoAnalyticsProvider from './components/video-analytics';
import zoolifeTheme from './grommetTheme';
import { generateTitle, isDev } from './helpers';
import { useIsHabitatTabbed } from './hooks';
import { getDisallowedAutoReloadComponentsCount } from './hooks/disallowAutoReload';
import QueryProvider from './query-provider';
import { useInitOneSignal } from './shared/pushNotifications/hooks';

const customBreakpoints = deepMerge(grommet, zoolifeTheme);
// we are manually loading FA css and this should prevent duplication
faConfig.autoAddCss = false;

const App = () => {
  const isTabbed = useIsHabitatTabbed();

  useInitOneSignal();

  useErrorBoundary((err) => {
    console.error(err);
    // TODO: log this somewhere (sentry, logRocket, or GA)
  });

  useEffect(() => {
    const handleFullscreenMode = () => {
      // document.fullscreenElement will point to the element that
      // is in fullscreen mode if there is one. If there isn't one,
      // the value of the property is null.
      if (document.fullscreenElement) {
        document.body.classList.add('fullscreen', 'group/fullscreen');
      } else {
        document.body.classList.remove('fullscreen', 'group/fullscreen');
      }
    };

    document.addEventListener('fullscreenchange', handleFullscreenMode);

    initializeGA();
    initializeMP();
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenMode);
    };
  }, []);

  useEffect(() => {
    const handleFocus = () => {
      if (getDisallowedAutoReloadComponentsCount() === 0 && isTabbed && document.visibilityState === 'visible' && !isDev()) {
        window.location.reload();
      }
    };

    document.addEventListener('visibilitychange', handleFocus);

    return () => {
      document.removeEventListener('visibilitychange', handleFocus);
    };
  }, [isTabbed]);

  const onRouteChange = useCallback(
    ({
      current: {
        props: { title, skipTitle },
      },
    }) => {
      if (!skipTitle && typeof window !== 'undefined') {
        document.title = generateTitle(title);
      }
    },
    [],
  );

  return (
    <QueryProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <GlobalsContextProvider>
            <Grommet theme={customBreakpoints} style={{ background: 'transparent' }}>
              <RemoteConfigUpdater />
              <ABTestProvider>
                <VideoAnalyticsProvider>
                  <Main onRouteChange={onRouteChange} />
                </VideoAnalyticsProvider>
              </ABTestProvider>
            </Grommet>
          </GlobalsContextProvider>
        </PersistGate>
      </Provider>
    </QueryProvider>
  );
};

export default App;
