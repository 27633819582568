import { LayoutDetector } from 'Components/LayoutDetector';
import { useRouter } from 'preact-router';

import { useSelector } from '@/redux/helper';

import MyAlbum from '.';

const MyAlbumLayoutDetector = () => {
  const [route] = useRouter();
  const logged = useSelector((state) => state.user.logged);
  const myUsername = useSelector((state) => state.user.username);
  const usernameReq = route?.matches?.userName;
  const isMe = logged && route?.matches?.userName === myUsername;
  document.title = isMe ? 'My Profile' : `${usernameReq}'s Profile`;

  return (
    <LayoutDetector desktop={<MyAlbum key={usernameReq} />} mobile={<MyAlbum key={usernameReq} />} title={document.title} />
  );
};

export default MyAlbumLayoutDetector;
