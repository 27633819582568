/**
 * Simple implementation of atom pattern.
 * It is used to share state between components and maintain the value during the whole lifetime of the app.
 */

import { isFunction } from 'lodash-es';
import { useSyncExternalStore } from 'preact/compat';
import { StateUpdater } from 'preact/hooks';

interface Atom<T> {
  get: () => T;
  set: (newValue: T) => void;
  subscribe: (callback: (value: T) => void) => () => void;
}

export const atom = <T>(defaultValue: T, isEqual?: (a: T, b: T) => boolean) => {
  let value = defaultValue;
  const subscribers = new Set<(value: T) => void>();

  const subscribe = (callback: (value: T) => void) => {
    subscribers.add(callback);
    return () => {
      subscribers.delete(callback);
    };
  };

  const get = () => value;

  const set: StateUpdater<T> = (newValue) => {
    const upcomingValue = isFunction(newValue) ? newValue(value) : newValue;
    // only update if the value is different
    if (isEqual ? !isEqual(upcomingValue, value) : upcomingValue !== value) {
      value = upcomingValue;
      subscribers.forEach((callback) => callback(value));
    }
  };

  return {
    get,
    set,
    subscribe,
  };
};

export const useAtomValue = <T>(atomInstance: Atom<T>) => {
  return useSyncExternalStore(atomInstance.subscribe, atomInstance.get);
};

export const useAtom = <T>(atomInstance: Atom<T>) => {
  const value = useAtomValue(atomInstance);
  return [value, atomInstance.set] as const;
};
