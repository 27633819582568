import clsx from 'clsx';
import Tag from 'Components/Tag';
import { h } from 'preact';

import style from './style.scss';

const HabitatStatus = ({ online, liveTalk, free, className }) => {
  let label = '';
  if (liveTalk) {
    label = 'Live Event';
  } else {
    label = online ? 'Online' : 'Asleep';
  }
  const variant = online && liveTalk ? 'liveTalk' : label === 'Asleep' ? 'offline' : label.toLowerCase();

  return (
    <div className={clsx(style.tag, className)}>
      <Tag className={style.tag} label={label} varient={variant} />
    </div>
  );
};

export default HabitatStatus;
